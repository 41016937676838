<template>
  <v-app>
    <div class="content app-content">
      <div class="content-header mb-6">
        <div class="content-header-left">
          <div class="row d-flex align-items-center justify-space-between">
            <div class="col-12 col-md-8 d-flex align-items-center">
              <router-link to="/competition" custom>
                <v-icon small class="content-header-title float-left mb-0">
                  fa-chevron-left
                </v-icon>
                <h5 class="content-header-title float-left mb-0 ml-2">
                  {{ $t("competition.all") }}
                </h5>
              </router-link>
              <h5 class="content-header-title float-left mb-0 ml-2">/</h5>
              <h5 class="content-header-title float-left mb-0 ml-2">
                {{
                  competition.setting_competition_informations[
                    "setting_competition_information_name_" +
                      $t("short_language")
                  ]
                }}
              </h5>
            </div>
          </div>
        </div>
      </div>
      <div class="container">
        <v-card>
          <v-row>
            <v-col class="d-flex justify-end">
              <v-btn
                class="mx-2"
                color="primary"
                small
                @click="onEditCompetition"
                >{{ $t("normal.edit_list") }}</v-btn
              >
            </v-col>
          </v-row>
          <v-row>
            <v-col class="d-flex align-items-center" cols="2">
              <v-img
                v-if="competition.setting_competition_media_profile_icon"
                class="d-flex justify-content-center center ma-5"
                :src="competition.setting_competition_media_profile_icon"
                width="128"
                contain
              />
              <v-img
                v-else
                class="d-flex justify-content-center center ma-5"
                src="@/assets/images/competition_placeholder.png"
                width="128"
                contain
              />
            </v-col>
            <v-col
              class="
                d-flex
                flex-column
                justify-center
                align-items-center
                border-right
                pa-2
              "
              cols="3"
            >
              <div>{{ name("th") }}</div>
              <div>{{ name("en") }}</div>
              <div>{{ competitionCode }}</div>
            </v-col>
            <v-col
              class="
                d-flex
                align-items-center
                justify-space-around
                text-center
                info-header
              "
            >
              <div>
                <div color="black">{{ $t("competition.abbreviation") }}</div>
                <div
                  class="grey--text"
                  v-if="this.$t('short_language') === 'th'"
                >
                  {{ abbreviation }}
                </div>
                <div class="grey--text" v-else>{{ abbreviationEn }}</div>
              </div>
              <div>
                <div color="black">{{ $t("competition.level") }}</div>
                <div class="grey--text">
                  {{ competitionLevel(competition.setting_competition_level) }}
                </div>
              </div>
              <div>
                <div color="black">{{ $t("competition.level") }}</div>
                <div class="grey--text">{{ competitionType }}</div>
              </div>
            </v-col>
          </v-row>
        </v-card>
        <v-card class="mt-16">
          <v-data-table
            :headers="headers"
            :items="sub_competitions.data"
            :single-select="singleSelect"
            :page.sync="params.page"
            :items-per-page="params.size"
            class="elevation-1 row-pointer"
            hide-default-footer
            @page-count="pageCount = $event"
            @click:row="onRowClick"
          >
            <template v-slot:no-data>
              <v-container class="d-flex flex-column align-items-center py-6 no-data">
                <v-img
                  src="@/assets/images/cup.png"
                  max-height="128"
                  contain
                  class="ma-2"
                ></v-img>
                <h4 class="grey--text text--lighten-1">
                  {{ $t("competition.not_exist") }}
                </h4>
                <h5 class="grey--text text--lighten-2">
                  {{ $t("competition.please_create") }}
                </h5>
              </v-container>
            </template>
            <template v-slot:[`item.image`]="{ item }">
              <v-img :src="item.image" contain width="40" class="ma-2"></v-img>
            </template>
            <template
              v-slot:[`item.setting_sub_competition_hostname`]="{ item }"
            >
              <div v-if="language === 'th'">
                {{ item.setting_sub_competition_hostname }}
              </div>
              <div v-else>{{ item.setting_sub_competition_hostname_en }}</div>
            </template>
            <template
              v-slot:[`item.setting_sub_competition_start_at`]="{ item }"
            >
              <div>{{ dateFormat(item.setting_sub_competition_start_at) }}</div>
            </template>
            <template v-slot:[`item.setting_sub_competition_end_at`]="{ item }">
              <div>{{ dateFormat(item.setting_sub_competition_end_at) }}</div>
            </template>
            <template v-slot:[`item.actions`]="{ item }">
              <v-menu offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon small v-bind="attrs" class="pa-2" v-on="on"
                    >fa-ellipsis-h</v-icon
                  >
                </template>
                <v-list>
                  <v-list-item v-for="(action, index) in actions" :key="index">
                    <v-btn text @click="onClick(action.key, item)">
                      <v-icon small class="mr-2">{{ action.icon }}</v-icon
                      >{{ action.text }}
                    </v-btn>
                  </v-list-item>
                </v-list>
              </v-menu>
            </template>
            <template v-slot:top="{}">
              <v-row class="px-6">
                <v-col class="d-flex justify-end">
                  <v-btn color="primary" @click="onAddSubCompetition"
                    >+ {{ $t("sub_competition.add") }}</v-btn
                  >
                </v-col>
              </v-row>
              <v-row class="px-6 d-flex flex-row justify-space-between">
                <v-col
                  cols="auto"
                  class="d-flex flex-row justify-center align-items-center"
                >
                  <span class="text-primary">{{
                    $t("sub_competition.list")
                  }}</span>
                </v-col>
                <v-col
                  cols="auto"
                  class="d-flex flex-row justify-center align-items-center"
                >
                  {{ $t("normal.search") }}
                  <div class="mx-3 search-box">
                    <v-text-field
                      v-model="params.name"
                      dense
                      outlined
                      hide-details="true"
                      :placeholder="$t('sub_competition.search_placholder')"
                    ></v-text-field>
                  </div>
                </v-col>
              </v-row>
            </template>
            <template v-slot:footer="{}">
              <TableFooter
                :perPage.sync="params.size"
                :currentPage.sync="params.page"
                :lastPage="sub_competitions.lastPage"
                :total="sub_competitions.total"
              />
            </template>
          </v-data-table>
        </v-card>
      </div>
    </div>
  </v-app>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { format } from "date-fns";
import { th } from "date-fns/locale";

import TableFooter from "@/components/TableFooter.vue";
import { languageService } from "../../helpers/language";
import MasterDataHelper from "@/helpers/master_data_helper.js";

export default {
  name: "CompetitionDetail",

  components: {
    TableFooter,
  },
  created() {
    this.subscription = languageService.getCurrent().subscribe((lang) => {
      if (lang === "en") {
        this.language = "en";
      } else {
        this.language = "th";
      }
    });
  },
  data() {
    return {
      language: "th",
      competitionId: null,
      selectPerson: [],
      singleSelect: false,
      pageSizes: [10, 20, 50, 100],
      range: [10, 20],
      dialogConfirmDelete: false,
      itemDeleting: null,
    };
  },

  mounted() {
    this.competitionId = this.$route.params.id;
    this.getCompetition(this.competitionId);
    this.fetchSubCompetition();
  },

  computed: {
    ...mapState("sub_competition", ["sub_competitions", "params"]),
    ...mapState("competition", ["competition"]),
    competitionType() {
      return this.competition?.global_config_standard_types[
        "global_config_standard_type_name_" + this.$t("short_language")
      ];
    },
    abbreviation() {
      return (
        this.competition?.setting_competition_informations
          ?.setting_competition_information_abbreviation || "-"
      );
    },
    abbreviationEn() {
      return (
        this.competition?.setting_competition_informations
          ?.setting_competition_information_abbreviation_en || "-"
      );
    },
    competitionCode() {
      return (
        this.competition?.setting_competition_informations
          ?.setting_competition_information_code || "-"
      );
    },
    actions() {
      return [
        { text: this.$t("normal.edit_list"), icon: "fa-pen", key: "edit" },
        {
          text: this.$t("sub_competition.delete_from"),
          icon: "fa-trash",
          key: "delete",
        },
      ];
    },
    headers() {
      return [
        {
          text: this.$t("sub_competition.year"),
          value: "setting_sub_competition_year",
          sortable: false,
        },
        {
          text: this.$t("sub_competition.name"),
          value: "setting_sub_competition_name_" + this.$t("short_language"),
          sortable: false,
        },
        {
          text: this.$t("sub_competition.host"),
          value: "setting_sub_competition_hostname",
          sortable: false,
        },
        {
          text: this.$t("sub_competition.start_date"),
          value: "setting_sub_competition_start_at",
          sortable: false,
        },
        {
          text: this.$t("sub_competition.end_date"),
          value: "setting_sub_competition_end_at",
          sortable: false,
        },
        { text: "", value: "actions", sortable: false },
      ];
    },
  },

  watch: {
    "params.page"() {
      this.fetchSubCompetition();
    },
    "params.size"() {
      this.params.page = 1;
      this.fetchSubCompetition();
    },
    "params.name"() {
      this.fetchSubCompetition();
    },
  },

  methods: {
    ...mapActions("sub_competition", ["getSubCompetitions", "deleteItem"]),
    ...mapActions("competition", ["getCompetition"]),
    name(language) {
      return (
        this.competition?.setting_competition_informations[
          `setting_competition_information_name_${language}`
        ] || "-"
      );
    },
    fetchSubCompetition() {
      this.getSubCompetitions({ competitionId: this.competitionId });
    },
    onRowClick(row) {
      this.$router.push({
        name: "sub-competition-detail",
        params: { cid: this.competitionId, sid: row.id, tab: "teams" },
      });
    },
    onEditCompetition() {
      this.$router.push({
        name: "competition-update",
        params: { id: this.competitionId },
      });
    },
    onAddSubCompetition() {
      this.$router.push({
        name: "add-sub-competition",
        params: { cid: this.competitionId },
      });
    },
    dateFormat(date) {
      return format(new Date(date), "dd/MM/yyyy", { locale: th });
    },
    async onClick(action, item) {
      if (action === "edit") {
        this.$router.push({
          name: "sub-competition-update",
          params: { cid: this.competitionId, sid: item.id },
        });
      } else if (action === "delete") {
        this.dialogConfirmDelete = true;
        this.itemDeleting = item;
        await this.$swal(MasterDataHelper.sweetAlertConfig(this.language)).then(
          async (result) => {
            if (result.value) {
              this.deleteItem({
                cid: this.competitionId,
                sid: this.itemDeleting.id,
              });
            }
          }
        );
      }
    },
    confirmDeleteItem() {
      this.dialogConfirmDelete = false;
      this.deleteItem({ cid: this.competitionId, sid: this.itemDeleting.id });
    },
    competitionLevel(level) {
      switch (level) {
        case "DISTRICT":
          return this.$t("competition_level.district");
        case "ZONE":
          return this.$t("competition_level.zone");
        case "REGIONAL":
          return this.$t("competition_level.regional");
        case "INTERNATIONAL":
          return this.$t("competition_level.national");
        case "CONTINENTAL":
          return this.$t("competition_level.continental");
        case "GLOBAL":
          return this.$t("competition_level.global");
        default:
          return "";
      }
    },
  },
};
</script>

<style scoped>
.content {
  background: #f8f8f8;
}
.row-pointer >>> tbody tr :hover {
  cursor: pointer;
}
.no-data {
  cursor: default !important;
}
</style>