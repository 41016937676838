import { render, staticRenderFns } from "./Tab2.vue?vue&type=template&id=776d80f0&scoped=true"
import script from "./Tab2.vue?vue&type=script&lang=js"
export * from "./Tab2.vue?vue&type=script&lang=js"
import style0 from "./Tab2.vue?vue&type=style&index=0&id=776d80f0&prod&lang=css&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "776d80f0",
  null
  
)

export default component.exports