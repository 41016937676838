<template>
  <v-app>
    <div class="content app-content">
			<div class="content-header">
				<div class="content-header-left">
					<div class="row d-flex align-items-center justify-space-between">
						<div class="col-12 col-md-8 d-flex align-items-center">
							<v-icon class="content-header-title float-left mb-0" small>
								fa-chevron-left
							</v-icon>
							<router-link to="/competition" custom>
								<h5 class="content-header-title float-left mb-0 ml-2">
									{{ $t('competition.all') }}
								</h5>
							</router-link>
							<h5 class="content-header-title float-left mb-0 ml-2">
								/
							</h5>
							<router-link :to="{ name: 'competition-detail', params: { id: competitionId } }" custom>
								<h5 class="content-header-title float-left mb-0 ml-2">
									{{ competition.setting_competition_informations['setting_competition_information_name_'+$t('short_language')] }}
								</h5>
							</router-link>
							<h5 class="content-header-title float-left mb-0 ml-2">
								/
							</h5>
							<template v-if="resultAdding">
								<div class='clickable' @click="resultAdding = false">
									<h5 class="content-header-title float-left mb-0 ml-2">
										{{ items[tab] ? items[tab].text : '' }}
									</h5>
								</div>
								<h5 class="content-header-title float-left mb-0 ml-2">
									/
								</h5>
								<h5 class="content-header-title float-left mb-0 ml-2">
									{{$t('competition.add_result_competition')}}
								</h5>
							</template>
							<h5 v-else
								class="content-header-title float-left mb-0 ml-2"
							>
								{{ items[tab] ? items[tab].text : '' }}
							</h5>
						</div>
						<v-btn
							v-if="resultAdding"
							color='primary'
							@click="onSaveResult"
						>{{ $t('normal.save') }}</v-btn>
					</div>
				</div>
			</div>
      <div class="container">
				<result-form
					v-if="resultAdding"
					class='pa-3'
					:versus="versus"
				></result-form>
				<template v-else>
					<v-tabs
						v-model="tab"
						:show-arrows="true"
						active-class="text-white tab-color"
						hide-slider
						@change="onChangeTab"
					>
						<v-tab
							class='my-0 app-rounded-top' v-for="(item, index) in items" :class='elevation' :key="index"
						>
							<v-icon small>{{ item.icon }}</v-icon>
							<div class="ml-2">
                <span> {{ item.text }}</span>
							</div>
						</v-tab>
					</v-tabs>
					<v-card class='py-6 mb-2'>
						<v-row>
							<v-col cols='2'>
								<v-img
									v-if="sub_competition.setting_sub_competition_media_profile_icon"
									class="d-flex justify-content-center center ma-5"
									:src="sub_competition.setting_sub_competition_media_profile_icon"
									width="128"
									contain
								/>
								<v-img
									v-else
									class="d-flex justify-content-center center ma-5"
									src='@/assets/images/sub_competition_placeholder.png'
									width="128"
									contain
								/>
							</v-col>
							<v-col cols='2' class='border-right competition-information'>
								<div class='info-header text-center text-primary'>
									{{sub_competition.setting_sub_competition_name_th}}
								</div>
								<div class='text-center'>
									{{sub_competition.setting_sub_competition_name_en}}
								</div>
								<div class='text-center border-bottom'>
									({{sub_competition.setting_sub_competition_abbreviation}})
								</div>
								<div class='mt-1 d-flex align-items-center'><v-icon small class='mx-1'>fa-trophy</v-icon> {{ $t('sub_competition.year') }}: {{sub_competition.setting_sub_competition_year}}</div>
								<div class='mt-1 d-flex align-items-center'><v-icon small class='mx-1'>fa-calendar</v-icon> {{ $t('sub_competition.date') }}: {{dateFormat}}</div>
								<div class='mt-1 d-flex align-items-center'><img src='@/assets/images/union.png' width='16px' class='mx-1' /> {{ $t('sub_competition.team') }} : {{ selected_teams.total }} {{ $t('sub_competition.team') }}</div>
							</v-col>
							<v-col cols='8' class='text-center info-header'>
								<v-row>
									<v-col class='d-flex justify-end'>
										<v-btn
											class='mx-4' color='primary' small
											@click="onEditSubCompetition"
										>{{ $t('normal.edit') }}</v-btn>
									</v-col>
								</v-row>
								<v-row>
									<v-col class='d-flex align-items-center justify-space-around text-center info-header'>
										<div>
											<div color='black'>{{ $t('competition.level') }}</div>
											<div class='grey--text'>{{ competitionLevel(competition.setting_competition_level) }}</div>
										</div>
										<div>
											<div color='black'>{{ $t('sub_competition.type') }}</div>
											<div class='grey--text'>{{ subCompetitionType }}</div>
										</div>
										<div>
											<div color='black'>{{ $t('competition.team_type') }}</div>
											<div class='grey--text'>{{ competitionType }}</div>
										</div>
									</v-col>
								</v-row>
							</v-col>
						</v-row>
					</v-card>
					<team-table
						v-if="tab == 0"
						@openTeamSelect="dialogSelectTeam = true"
						@showSnackBar="showSnackBar"
					/>
					<competition-round
						v-if="tab == 1"
						@add="onAddCompetitionRound"
						@edit="onEditCompetitionRound"
						@addResult="onAddResult"
						@updateResult="onUpdateResult"
						@addGroup="onAddGroup"
						@editGroup="onEditGroup"
						@addGroupResult="onAddGroupResult"
						@updateGroupResult="onUpdateGroupResult"
					/>
					<image-gallery v-if="tab == 2" />
				</template>
			</div>
		</div>
		<v-dialog
			v-model="dialogSelectTeam"
			width="600px"
			v-bind:hide-overlay="false"
		>
			<TeamSelect @close="dialogSelectTeam = false"/>
		</v-dialog>
		<v-dialog
			v-model="dialogCompetitionRound"
			width="800px"
			@click:outside="competition_info = {}"
		>
			<CompetitionRoundModal
				:round.sync="competition_round"
				:section_id="competition_info.id"
				:desc_th="competition_info.competition_section_description_th"
				:desc_en="competition_info.competition_section_description_en"
				@close="onCloseRoundModal"
			/>
		</v-dialog>
		<v-dialog
			v-model="dialogAddGroup"
			width="800px"
		>
			<LeagueGroup
				:competitionSectionId="versus.csid"
				:groupId="versus.gid"
				@close="dialogAddGroup = false"
			/>
		</v-dialog>
		<v-snackbar
			v-model="snackbar.visible"
			:color="snackbar.color"
			:timeout="1000"
			:top="snackbar.position === 'top'"
			rounded="true"
		>
      <v-layout align-center pr-4>
        <v-icon small class="pr-3">
					{{ snackbar.icon }}
				</v-icon>
        <v-layout column>
					<strong v-if="success">{{ success }}</strong>
					<strong v-else>{{ snackbar.title }}</strong>
        </v-layout>
      </v-layout>
    </v-snackbar>
  </v-app>
</template>

<script>
import ImageGallery from '@/components/Competition/Sub/ImageGallery'
import TeamTable from '@/components/Competition/Sub/TeamTable'
import CompetitionRound from '@/components/Competition/Sub/CompetitionRound'
import TeamSelect from '@/components/Competition/Modal/TeamSelect'
import { mapState, mapActions } from 'vuex';

import CompetitionRoundModal from '@/components/Competition/Modal/CompetitionRound'
import ResultForm from '@/components/Competition/Result/ResultForm'
import LeagueGroup from '@/components/Competition/Modal/LeagueGroup'

export default {
	name: 'SubCompetitionDetail',

	components: {
		TeamTable,
		TeamSelect,
		CompetitionRound,
		ImageGallery,
		CompetitionRoundModal,
		ResultForm,
		LeagueGroup,
	},

	watch: {
		// tab(val) {
		// 	// this.resultAdding = false
		// 	this.$router.push({ name: 'sub-competition-detail', params: { cid: this.competitionId, sid: this.subCompetitionId, tab: val } })
		// },
		competition() {
			this.getTeams({ id: this.subCompetitionId, type: this.competition.global_config_standard_types.global_config_standard_type_name_en })
		},
		success(val) {
			if (val) {
				this.snackbar.visible = true
				this.snackbar.title = val
			}
		},
	},

	data() {
		return {
			competitionId: null,
			subCompetitionId: null,
			headers: [
				{
					text: "ประจำปี",
					value: "season",
				},
				{
					text: "ชื่อการแข่งขัน (ภาษาไทย)",
					value: "sub_competition_count",
				},
				{ text: "เจ้าภาพ", value: "type" },
				{ text: "วันที่เริ่ม", value: "rank" },
				{ text: "วันที่จบ", value: "team_type" }
			],
			selectPerson: [],
			singleSelect: false,
			pageSizes: [10, 20, 50, 100],
			range: [10, 20],
			pageSize: 10,
			currentPage: 1,
			searchName: "",
			setting_sub_competition_id: '',
			tab: 0,
			dialogCompetitionRound: false,
			dialogSelectTeam: false,
			snackbar: {
				visible: false,
				color: 'red',
				timeout: 2000,
				icon: 'fa-info',
				title: 'ลบข้อมูลที่เลือกแล้ว',
				text: 'ลบข้อมูลที่เลือกแล้ว',
				position: 'top',
			},
			resultAdding: false,
			resultMatch: {},
			dialogAddGroup: false,
			competition_round: {},
			competition_info: {},
			versus: {
				csid: null,
				gid: null,
				mid: null,
			}
		}
	},

	mounted() {
		this.competitionId = this.$route.params.cid
		this.subCompetitionId = this.$route.params.sid

		this.getCompetition(this.competitionId)
		this.getSubCompetition({ cid: this.competitionId, sid: this.subCompetitionId })
		switch (this.$route.params.tab) {
			case 'competition':
			case "1":
				this.tab = 1;
				break;
			case 'gallery':
			case "2":
				this.tab = 2;
				break;
			default: this.tab = 0;
		}
	},

	computed: {
		...mapState('competition', ['competition']),
		...mapState('sub_competition', ['sub_competitions', 'sub_competition', 'rounds', 'selected_teams', 'success']),
		...mapState('match', ['resultForm', 'home', 'away']),
		...mapState('versus_match', ['referees','teams','left_staff','right_staff']),
		dateFormat() {
			const start = new Date(this.sub_competition.setting_sub_competition_start_at)
			const end = new Date(this.sub_competition.setting_sub_competition_end_at)
			try {
				let fmt2 = new Intl.DateTimeFormat("en-US", {
					year: 'numeric',
					month: 'long',
					day: 'numeric'
				});
				return fmt2.formatRange(start, end)
			} catch (err) {
				return ''
			}
		},

		competitionType() {
			return this.competition?.global_config_standard_types['global_config_standard_type_name_'+this.$t('short_language')];
		},
		subCompetitionType() {
			if ( this.$t('short_language') === 'en' ) {
				return this.sub_competition.setting_sub_competition_category_en
			}
			return this.sub_competition.setting_sub_competition_category
		},
		items() {
			return [
				{ text: this.$t('sub_competition.team_join'), icon: 'fa-users', key: 1 },
				{ text: this.$t('sub_competition.competition'), icon: 'fa-trophy' },
				{ text: this.$t('sub_competition.image_and_vedio'), icon: 'fa-video' },
			];
		},
	},

  methods: {
		...mapActions('competition', ['getCompetition']),
		...mapActions('match', ['clearResultForm']),
		...mapActions('sub_competition',
			['getSubCompetition', 'saveCompetitionRound', 'getTeams']
		),
		...mapActions('data_model', ['setGroup']),
		...mapActions('versus_match', ['createOrUpdateMatch']),

		onChangeTab(tab) {
			this.$router.push({ name: 'sub-competition-detail', params: { cid: this.competitionId, sid: this.subCompetitionId, tab: tab } })
		},

		onEditSubCompetition() {
				this.$router.push({
					name: 'sub-competition-update',
					params: { cid: this.competitionId, sid: this.subCompetitionId }
				})
		},

		onAddCompetitionRound(round) {
			this.competition_round = round
			this.competition_info = {}
			this.dialogCompetitionRound = true
		},

		onEditCompetitionRound(info) {
			this.competition_info = info
			this.competition_round = info.competition_section_round_name
			this.dialogCompetitionRound = true
		},

		onCloseRoundModal(competition_section_id, sectionKey) {
			this.dialogCompetitionRound = false
			if (competition_section_id) {
				this.onAddResult(competition_section_id, sectionKey)
			}
		},

		onRowClick() {
		},

		onAddSubCompetition() {
			this.$router.push({ name: 'add-sub-competition' })
		},
		elevation(tab) {
			return this.items[tab]
		},
		showSnackBar() {
			this.$set(this.snackbar, 'visible', true);
		},

		onAddResult(comSecId, sectionKey) {
			this.resultAdding = true
			this.clearResultForm()
			this.$set(this, 'versus', {
				csid: comSecId,
				gid: null,
				mid: null,
				skey: sectionKey,
			})
		},

		onUpdateResult(match, sectionKey) {
			this.resultAdding = true
			this.clearResultForm()
			this.$set(this, 'versus', {
				csid: match.competition_section_id,
				gid: null,
				mid: match.id,
				skey: sectionKey
			})
		},

		onAddGroupResult(data, sectionKey) {
			this.resultAdding = true
			this.clearResultForm()
			this.$set(this, 'versus', {
				csid: data.csid,
				gid: data.groupId,
				mid: null,
				skey: sectionKey,
				left_id: data.left_id,
				right_id: data.right_id,
			})
		},

		onUpdateGroupResult(data, sectionKey) {
			this.resultAdding = true
			this.clearResultForm()
			this.$set(this, 'versus', {
				csid: data.competition_section_id,
				gid: data.groupId,
				mid: data.match.id,
				skey: sectionKey,
			})
		},

		onEditGroup(data) {
			this.dialogAddGroup = true
			this.clearResultForm()
			this.$set(this, 'versus', {
				csid: data.csid,
				gid: data.gid,
				mid: null,
			})
		},

		onAddGroup(comSecId) {
			this.dialogAddGroup = true
			this.clearResultForm()
			this.setGroup({
				competition_group_stage_name: '',
				competition_group_stage_name_en: '',
				competition_group_stage_team_join: [],
			})
			this.$set(this, 'versus', {
				csid: comSecId,
				gid: null,
				mid: null,
			})
		},

		async onSaveResult() {
			var result = JSON.parse(JSON.stringify(this.resultForm))
			let homePlayers = []
			this.home.captain.map(player => {
				player.captain = true
				homePlayers.push(player)
			})
			this.home.vice_captain.map(player => {
				player.vice_captain = true
				homePlayers.push(player)
			})
			this.home.lineup.map(player => {
				player.main = true
				homePlayers.push(player)
			})
			this.home.sub.map(player => {
				player.main = false
				homePlayers.push(player)
			})
			result.versus_match_team_left.versus_match_team_detail_player = homePlayers.map(p => {
				p.accountPosition = p.accountPosition?.global_config_position_abbreviation || p.accountPosition || 'GK'
				return p
			})

			let awayPlayers = []
			this.away.captain.map(player => {
				player.captain = true
				awayPlayers.push(player)
			})
			this.away.vice_captain.map(player => {
				player.vice_captain = true
				awayPlayers.push(player)
			})
			this.away.lineup.map(player => {
				player.main = true
				awayPlayers.push(player)
			})
			this.away.sub.map(player => {
				player.main = false
				awayPlayers.push(player)
			})
			result.versus_match_team_right.versus_match_team_detail_player = awayPlayers.map(p => {
				p.accountPosition = p.accountPosition?.global_config_position_abbreviation || p.accountPosition || 'GK'
				return p
			})

			const teamRight = this.teams.find(te=>te.teamId === result?.versus_match_team_right?.versus_match_team_id)
			const teamLeft = this.teams.find(te=>te.teamId === result?.versus_match_team_left?.versus_match_team_id)

			result.versus_match_team_right.versus_match_team_image = teamRight?.teamImage
			result.versus_match_team_left.versus_match_team_image = teamLeft?.teamImage

			result.versus_match_team_right.versus_match_team_name = teamRight?.teamName
			result.versus_match_team_right.versus_match_team_name_en = teamRight?.teamNameEn
			result.versus_match_team_left.versus_match_team_name = teamLeft?.teamName
			result.versus_match_team_left.versus_match_team_name_en = teamLeft?.teamNameEn

			const staffRight = this.right_staff.find(staff=>staff.staffId === result?.versus_match_team_right?.versus_match_team_detail_staff)
			const staffLeft = this.left_staff.find(staff=>staff.staffId === result?.versus_match_team_left?.versus_match_team_detail_staff)

			result.versus_match_team_right.versus_match_team_detail_staff = staffRight
			result.versus_match_team_left.versus_match_team_detail_staff = staffLeft

			// const teamHolder = this.teams.find(te=>te.teamId === result?.versus_match_stadium_holder)
			// result.versus_match_stadium_holder.versus_match_stadium_holder_id = teamHolder?.teamId
			// console.log('teamHolder',teamHolder);

			result.versus_match_referee = Object.keys(result.versus_match_referee).reduce((data, key) => {
				let referee = this.referees.find(ref => ref.value === result.versus_match_referee[key])
				if (referee) {
					data[key] = referee.data
				}
				return data
			}, {})

			if (result.versus_match_team_left?.versus_match_team_detail_new_staff) {
				result.versus_match_team_left.versus_match_team_detail_staff = {
					staffName: result.versus_match_team_left.versus_match_team_detail_new_staff
				}
				delete result.versus_match_team_left.versus_match_team_detail_new_staff
			}

			if (result.versus_match_team_right?.versus_match_team_detail_new_staff) {
				result.versus_match_team_right.versus_match_team_detail_staff = {
					staffName: result.versus_match_team_right.versus_match_team_detail_new_staff
				}
				delete result.versus_match_team_right.versus_match_team_detail_new_staff
			}

			if (!result.versus_match_visitors) result.versus_match_visitors = 0

			let error = await this.createOrUpdateMatch({
				csid: this.versus.csid,
				gid: this.versus.gid,
				payload: result,
			})

			if (!error) {
				this.resultAdding = false
				this.clearResultForm()
			} else {
				this.snackbar.visible = true
				// console.log(error, error[0])
				Array.isArray(error) ? this.snackbar.title = error[0] : this.snackbar.title = error
			}
		},

		competitionLevel(level) {
			switch(level) {
				case 'DISTRICT': return this.$t('competition_level.district')
				case 'ZONE': return this.$t('competition_level.zone')
				case 'REGIONAL': return this.$t('competition_level.regional')
				case 'INTERNATIONAL': return this.$t('competition_level.national')
				case 'CONTINENTAL': return this.$t('competition_level.continental')
				case 'GLOBAL': return this.$t('competition_level.global')
			}
		},
  },
}
</script>

<style scoped lang="scss">
.competition-information {
	font-size: 14px;
}
.info-header {
	font-size: 20px;
}
.clickable {
	cursor: pointer;
}

.tab-color {
	background-color: #0d6efd;
}
.v-tab {
	margin: 0 2px;
	&:not(.v-tab--active) {
		background: #f1f1f5;
	}
}
.app-rounded-top {
	border-top-left-radius: 10px;
	border-top-right-radius: 10px;
}
</style>