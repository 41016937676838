<template>
	<v-card class='pa-3'>
		<v-row class="px-6 d-flex flex-row justify-space-between">
			<v-col cols='auto' class='d-flex flex-row justify-center align-items-center'>
				<span class='text-primary'>{{ $t('sub_competition.add_team_sub') }}</span>
			</v-col>
			<v-col class='d-flex justify-end'>
				<v-btn text @click="$emit('close')">{{ $t('normal.cancel') }}</v-btn>
				<v-btn class='ml-2' color="primary" @click="saveSelected">{{ $t('normal.save') }}</v-btn>
			</v-col>
		</v-row>
		<v-row class="px-6 d-flex flex-row justify-space-between">
			<v-col cols='auto' class='d-flex flex-row justify-center align-items-center'>
				{{ $t('competition.team_type') }} : {{ competitionType }}
			</v-col>
		</v-row>
		<v-row class="px-6 d-flex flex-row justify-space-between">
			<v-col cols='auto' class='d-flex flex-row justify-center align-items-center'>
				<div class="search-box">
					<v-text-field
						v-model="searchName"
						dense
						outlined
						hide-details="true"
						:placeholder="$t('normal.search')"
					>
						<template v-slot:prepend-inner>
							<div class="my-1 mr-1">
								<v-icon color="#92929D" small>fa-search</v-icon>
							</div>
						</template>
					</v-text-field>
				</div>
			</v-col>
		</v-row>
		<v-data-table
			class='elevation-0 my-2'
			:items="filteredTeams"
			item-key="id"
			:page="0"
			:headers="headers"
			:items-per-page="filteredTeams.length"
			:hide-default-footer="true"
			:hide-default-header="true"
			v-model="selected"
			show-select
		>
			<template v-slot:[`item.team`]="{item}">
				<div class="d-flex align-items-center">
					<v-img
						:src="item.setting_team_informations.medias.setting_team_media_profile_url"
						max-height="20"
						max-width="20"
						contain
						class="ma-2"
					></v-img>
					<span>{{item.setting_team_informations['setting_team_information_name_'+$t('short_language')]}}</span>
				</div>
			</template>
		</v-data-table>
		<!-- <template v-for="(item, index) in filteredTeams">
			<v-checkbox
				v-model="checkboxes[index]"
				:key="index"
				class='mx-5 mt-3 pa-0'
			>
				<template v-slot:label>
					<v-img
						:src="item.image"
						max-height="128"
						max-width="20"
						contain
						class="ma-2"
					></v-img>
					<span>{{item.setting_team_information_name_th}}</span>
				</template>
			</v-checkbox>
		</template> -->
	</v-card>
</template>

<script>
import { mapState, mapActions } from 'vuex'

export default {
	data() {
		return {
			searchName: '',
      headers: [
        { text: "", value: "team", width: "90" },
			],
			selected: [],
		}
	},

	mounted() {
	},

	computed: {
		...mapState('sub_competition', ['teams', 'selected_teams']),
		...mapState('competition', ['competition']),

		filteredTeams() {
			let teams = this.teams.map(team => {
				team.width = '90%'
				return team
			})
			if (this.searchName) {
				return teams.filter(team => {
					return (team.setting_team_information_name_th && team.setting_team_information_name_th.toLowerCase().includes(this.searchName.toLowerCase())) ||
					(team.setting_team_information_name_en && team.setting_team_information_name_en.toLowerCase().includes(this.searchName.toLowerCase()))
				})
			}
			return teams
		},

		competitionType() {
			return this.competition?.global_config_standard_types[('global_config_standard_type_name_'+this.$t('short_language'))]
		},
	},

	methods: {
		...mapActions('sub_competition', ['updateCompetitionTeams']),

		saveSelected() {
			let selected = this.selected.map(select => select.id)
			this.updateCompetitionTeams(selected)
			this.$emit('close')
		},
	}
}
</script>