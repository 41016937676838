<template>
  <v-card class="my-10">
    <v-data-table
      :items="teamData.data"
      :headers="headers"
      item-key="id"
      :page.sync="page"
      :items-per-page="pageSize"
      v-model="selected"
      class="elevation-1"
      show-select
      @page-count="pageCount = $event"
      :hide-default-footer="true"
    >
      <template v-slot:top="{}">
        <v-row class="px-6">
          <v-col class="d-flex justify-end">
            <v-btn color="primary" @click="$emit('openTeamSelect')"
              >+ {{ $t("sub_competition.select_team_join") }}</v-btn
            >
          </v-col>
        </v-row>
        <v-row class="px-6 d-flex flex-row justify-space-between">
          <v-col
            cols="auto"
            class="d-flex flex-row justify-center align-items-center"
          >
            <span class="text-primary">{{
              $t("sub_competition.team_join")
            }}</span>
            <v-btn class="ml-2" text @click="deleteSelectedItem">
              <v-icon color="grey" x-small class="mr-2"> fa-trash </v-icon>
              {{ $t("normal.delete_select") }}
            </v-btn>
          </v-col>
          <v-col
            cols="auto"
            class="d-flex flex-row justify-center align-items-center"
          >
            {{ $t("normal.search") }}
            <div class="mx-3 search-box">
              <v-text-field
                v-model="searchName"
                dense
                outlined
                hide-details="true"
                :placeholder="$t('sub_competition.search_team_placholder')"
              ></v-text-field>
            </div>
          </v-col>
        </v-row>
      </template>
      <template v-slot:no-data>
        <div
          class="
            d-flex
            flex-column
            align-items-center
            justify-content-center
            py-6
          "
          style="min-height: 400px"
        >
          <v-img
            src="@/assets/images/cup.png"
            max-height="128"
            contain
            class="ma-2"
          ></v-img>
          <h4 class="grey--text text--lighten-1">
            {{ $t("sub_competition.not_exist") }}
          </h4>
          <h5 class="grey--text text--lighten-2">
            {{ $t("sub_competition.please_add_team") }}
          </h5>
        </div>
      </template>
      <template v-slot:[`item.team`]="{ item }">
        <div class="d-flex flex-row align-items-center">
          <v-img
            :src="item.setting_team_media_profile_url"
            max-height="20"
            max-width="20"
            contain
            class="ma-2"
          ></v-img>
          <span class="mx-2">{{
            item["setting_team_information_name_" + $t("short_language")]
          }}</span>
        </div>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-icon small @click="deleteItem(item)"> fa-trash </v-icon>
      </template>
    </v-data-table>
    <!-- <br> -->
    <div class="" style="background: #ffffff">
      <TableFooter
        :perPage.sync="pageSize"
        :currentPage.sync="currentPage"
        :lastPage="teamData.lastPage"
        :total="teamData.total"
      />
    </div>
  </v-card>
</template>

<script>
import { mapActions, mapState } from "vuex";
import TableFooter from "../../TableFooter.vue";
import MasterDataHelper from "@/helpers/master_data_helper.js";

export default {
  name: "TeamTable",
  components: {
    TableFooter,
  },

  mounted() {
    this.subCompetitionId = this.$route.params.sid;
    this.getTeamsInSubCompetition({
      sid: this.subCompetitionId,
      data: this.params,
    });
  },

  computed: {
    ...mapState("sub_competition", ["selected_teams"]),
    teamData() {
      return this.selected_teams || [];
    },
    params() {
      return {
        size: this.pageSize,
        page: this.currentPage,
        name: this.searchName,
      };
    },
    headers() {
      return [
        { text: this.$t("sub_competition.team"), value: "team", width: "70%" },
        {
          text: this.$t("address.country"),
          value: "setting_team_region_country",
          width: "20%",
        },
        { text: "", value: "actions", sortable: false, width: "10%" },
      ];
    },
    buttonEdit() {
      return [
        { title: this.$t("normal.edit") },
        { title: this.$t("normal.delete_list") },
      ];
    },
  },
  methods: {
    ...mapActions("sub_competition", [
      "deleteCompetitionTeams",
      "getTeamsInSubCompetition",
    ]),
    async deleteItem(item) {
      this.deletingItem = item;
      await this.$swal(
        MasterDataHelper.sweetAlertConfig(this.$t("short_language"))
      ).then(async (result) => {
        if (result.value) {
          this.deleteCompetitionTeams([this.deletingItem.id]);
        }
      });
    },
    async deleteSelectedItem() {
      await this.$swal(
        MasterDataHelper.sweetAlertConfig(this.$t("short_language"))
      ).then(async (result) => {
        if (result.value) {
          if (this.selected.length > 0) {
            this.deleteCompetitionTeams(this.selected.map((team) => team.id));
          }
        }
      });
    },
    closeDelete() {
      this.dialogConfirmDelete = false;
      this.deletingItem = null;
    },
    confirmDeleteItem() {
      if (this.deletingItem) {
        this.deleteCompetitionTeams([this.deletingItem.id]);
      } else if (this.selected.length > 0) {
        this.deleteCompetitionTeams(this.selected.map((team) => team.id));
      }
      this.dialogConfirmDelete = false;
      this.deletingItem = null;
    },
  },
  watch: {
    currentPage: function () {
      this.getTeamsInSubCompetition({
        sid: this.subCompetitionId,
        data: this.params,
      });
    },
    pageSize: function () {
      this.currentPage = 1;
      this.getTeamsInSubCompetition({
        sid: this.subCompetitionId,
        data: this.params,
      });
    },
    searchName: function () {
      this.getTeamsInSubCompetition({
        sid: this.subCompetitionId,
        data: this.params,
      });
    },
  },
  data() {
    return {
      page: 1,
      pageCount: 0,
      pageCounts: [10, 20, 50, 100],
      itemsPerPage: 10,
      range: [10, 20],
      api: "http://localhost:3000/users",
      pageSize: 10,
      currentPage: 1,
      searchName: "",
      selected: [],
      dialogConfirmDelete: false,
      deletingItem: null,
    };
  },
};
</script>

<style lang="scss" scoped>
.content {
  background: #f8f8f8;
}

.select-amount {
  padding: 0 10px;
  background: #1e73fd;
  color: white;
}

.search-box {
  width: 360px;
}

.page-size-select {
  width: 85px;
}
</style>
