<template>
  <div class="row no-gutters" data-app>
    <v-form ref="form" class="needs-validation row" novalidate="">
        <div class="col-md-4 col-xl-3 col-12">
          <div class="p-2">
            <div
              class="
                m-md-2
                d-flex
                flex-column
                justify-center
                align-items-center
              "
            >
              <div class="container">
                <v-img
                  v-if="profile"
                  :src="profile"
                  width="100%"
                  height="100%"
                  contain
                  class="mb-2 rounded-pill"
                />
                <v-img
                  v-else
                  src="@/assets/images/NoImage.png"
                  class="
                    profileImage
                    d-flex
                    justify-content-center
                    center
                    p-1
                    rounded-pill
                  "
                ></v-img>
                <v-icon
                  style="font-size: 1rem"
                  class="ma-2 cursor-pointer camera-icon"
                  >fa-camera</v-icon
                >
                <input
                  type="file"
                  :name="fieldname"
                  @change="
                    filesChange($event.target.name, $event.target.files);
                    fileCount = $event.target.files.length;
                  "
                  accept="image/*"
                  class="input-file"
                />
              </div>

              <div class="ma-2">
                <h4 class="text-center">
                  {{ competitionName }}
                  <!-- {{
								competition.setting_competition_informations
									.setting_competition_information_name_th
							}} -->
                </h4>
              </div>
            </div>

            <div
              class="border-top border-bottom py-10 px-3"
              style="overflow-x: auto"
            >
              <div class="text-center h4 my-1">
                {{ $t("contact_information.contact") }}
                <v-icon
                  @click="onClickSocialContact"
                  style="font-size: 1rem"
                  class="ml-2 cursor-pointer"
                  >fa-edit</v-icon
                >
              </div>
              <div class="row">
                <div class="col-4">{{ $t("contact_information.phone") }}</div>
                <div class="col-8">
                  {{
                    social_contacts.setting_competition_social_contact_phone ||
                    "-"
                  }}
                </div>
              </div>
              <div class="row">
                <div class="col-4">{{ $t("contact_information.email") }}</div>
                <div class="col-8">
                  {{
                    social_contacts.setting_competition_social_contact_email ||
                    "-"
                  }}
                </div>
              </div>
              <div class="row">
                <div class="col-4">
                  {{ $t("contact_information.facebook") }}
                </div>
                <div class="col-8">
                  {{
                    social_contacts.setting_competition_social_contact_facebook ||
                    "-"
                  }}
                </div>
              </div>
              <div class="row">
                <div class="col-4">{{ $t("contact_information.line_id") }}</div>
                <div class="col-8">
                  {{
                    social_contacts.setting_competition_social_contact_line ||
                    "-"
                  }}
                </div>
              </div>
              <div class="row">
                <div class="col-4">
                  {{ $t("contact_information.instagram") }}
                </div>
                <div class="col-8">
                  {{
                    social_contacts.setting_competition_social_contact_instagram ||
                    "-"
                  }}
                </div>
              </div>
              <div class="row">
                <div class="col-4">{{ $t("contact_information.youtube") }}</div>
                <div class="col-8">
                  {{
                    social_contacts.setting_competition_social_contact_youtube ||
                    "-"
                  }}
                </div>
              </div>
              <div class="row">
                <div class="col-4">{{ $t("contact_information.twitter") }}</div>
                <div class="col-8">
                  {{
                    social_contacts.setting_competition_social_contact_twitter ||
                    "-"
                  }}
                </div>
              </div>
              <div class="row">
                <div class="col-4">{{ $t("contact_information.website") }}</div>
                <div class="col-8">
                  {{
                    social_contacts.setting_competition_social_contact_website ||
                    "-"
                  }}
                </div>
              </div>
              <div class="row">
                <div class="col-4">{{ $t("contact_information.fax") }}</div>
                <div class="col-8">
                  {{
                    social_contacts.setting_competition_social_contact_fax ||
                    "-"
                  }}
                </div>
              </div>
            </div>
            <div class="text-center my-3 font-weight-bolder">
              {{ $t("public.status") }}
            </div>
            <div class="row border-bottom m-md-2 py-3">
              <div class="col-6">
                <checkbox
                  :checked="competition.setting_competition_published"
                  v-model="activeStatus"
                >
                  <span
                    v-if="competition.setting_competition_published"
                    class="input-box"
                    slot="input-box"
                  >
                    <v-icon>fa-check</v-icon>
                  </span>
                  <span v-if="competition.setting_competition_published">{{
                    $t("public.public")
                  }}</span>
                  <span v-else>{{ $t("public.no_public") }}</span>
                </checkbox>
              </div>
              <div class="col-6">
                <div>
                  <label>
                    {{ $t("public.create_date") }}
                  </label>
                </div>
                <div>
                  <label class="text-primary">
                    {{
                      competition.created_at
                        ? checkDate(competition.created_at)
                        : ""
                    }}
                  </label>
                </div>
              </div>
            </div>
            <div class="row m-md-2">
              <div class="col-6">
                <div>
                  <label>
                    {{ $t("public.create_by") }}
                  </label>
                </div>
                <div>
                  <label style="word-break: break-all">
                    {{ competition.updated_by }}
                  </label>
                </div>
              </div>
              <div class="col-6">
                <div>
                  <label>
                    {{ $t("public.update_date") }}
                  </label>
                </div>
                <div>
                  <label class="text-primary">
                    {{
                      competition.updated_at
                        ? checkDate(competition.updated_at)
                        : ""
                    }}
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-md-8 col-12 border-left">
          <v-form ref="formRef">
            <div>
              <div class="border-bottom p-2">
                <div>
                  <h4 class="text-primary">
                    {{ $t("create_competition.information") }}
                  </h4>
                </div>
                <div>
                  <div class="row">
                    <div class="col-sm-6 col-12">
                      <label class="form-label" for="basic-addon-name">
                        {{ $t("create_competition.id") }}
                      </label>
                      <v-text-field
                        v-model="
                          competition.setting_competition_informations
                            .setting_competition_information_code
                        "
                        dense
                        :filled="isFilled"
                        outlined
                      ></v-text-field>
                    </div>
                    <div class="col-sm-6 col-12">
                      <label class="form-label" for="basic-addon-name">
                        {{ $t("create_competition.team_type") }}
                      </label>
                      <v-radio-group
                        v-model="competition.global_config_standard_types.id"
                        active-class="active-radio"
                        row
                        dense
                        class="d-flex align-items-center"
                      >
                        <v-radio
                          on-icon="fa-circle"
                          :label="$t('competition_team_type.national_team')"
                          value="1"
                        ></v-radio>
                        <v-radio
                          on-icon="fa-circle"
                          :label="$t('competition_team_type.club')"
                          value="2"
                        ></v-radio>
                      </v-radio-group>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-sm-6 col-12">
                      <div class="form-group">
                        <label class="form-label" for="basic-addon-name"
                          >{{ $t("create_competition.name_th") }}
                          <span color="red">*</span></label
                        >
                        <v-text-field
                          v-model="
                            competition.setting_competition_informations
                              .setting_competition_information_name_th
                          "
                          :rules="ruleForm"
                          dense
                          outlined
                          :placeholder="$t('create_competition.name_th')"
                        ></v-text-field>
                      </div>
                    </div>
                    <div class="col-sm-6 col-12">
                      <div class="form-group">
                        <label class="form-label" for="basic-addon-name"
                          >{{ $t("create_competition.name_en") }}
                          <span color="red">*</span></label
                        >
                        <v-text-field
                          v-model="
                            competition.setting_competition_informations
                              .setting_competition_information_name_en
                          "
                          :rules="ruleForm"
                          dense
                          outlined
                          :placeholder="$t('create_competition.name_en')"
                        ></v-text-field>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-sm-6 col-12">
                      <div class="form-group">
                        <label class="form-label" for="basic-addon-name">{{
                          $t(
                            "create_competition.association_or_organizer_name_th"
                          )
                        }}</label>
                        <v-text-field
                          v-model="
                            competition.setting_competition_informations
                              .setting_competition_information_organizer_th
                          "
                          dense
                          outlined
                          :placeholder="
                            $t(
                              'create_competition.association_or_organizer_name_th'
                            )
                          "
                        ></v-text-field>
                      </div>
                    </div>
                    <div class="col-sm-6 col-12">
                      <div class="form-group">
                        <label class="form-label" for="basic-addon-name">{{
                          $t(
                            "create_competition.association_or_organizer_name_en"
                          )
                        }}</label>
                        <v-text-field
                          v-model="
                            competition.setting_competition_informations
                              .setting_competition_information_organizer_en
                          "
                          dense
                          outlined
                          :placeholder="
                            $t(
                              'create_competition.association_or_organizer_name_en'
                            )
                          "
                        ></v-text-field>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-sm-6 col-12">
                      <div class="form-group">
                        <label class="form-label" for="basic-addon-name">{{
                          $t("create_competition.abbreviation_th")
                        }}</label>
                        <v-text-field
                          v-model="
                            competition.setting_competition_informations
                              .setting_competition_information_abbreviation
                          "
                          dense
                          outlined
                          :placeholder="
                            $t('create_competition.abbreviation_th')
                          "
                        ></v-text-field>
                      </div>
                    </div>
                    <div class="col-sm-6 col-12">
                      <div class="form-group">
                        <label class="form-label" for="basic-addon-name">{{
                          $t("create_competition.abbreviation_en")
                        }}</label>
                        <v-text-field
                          v-model="
                            competition.setting_competition_informations
                              .setting_competition_information_abbreviation_en
                          "
                          dense
                          outlined
                          :placeholder="
                            $t('create_competition.abbreviation_en')
                          "
                        ></v-text-field>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="border-bottom p-2">
                <div>
                  <h4 class="text-primary">
                    {{ $t("create_competition.detail") }}
                  </h4>
                </div>
                <div class="form-group col-sm-6 col-12">
                  <label class="form-label" for="basic-addon-name">
                    {{ $t("competition.level") }}
                  </label>
                  <v-select
                    v-model="competition.setting_competition_level"
                    :items="levels"
                    :placeholder="$t('normal.please_select')"
                    :menu-props="{ bottom: false, offsetX: false }"
                    dense
                    :rules="ruleForm"
                    required
                    outlined
                  ></v-select>
                </div>
              </div>
              <div class="p-2">
                <div>
                  <h4 class="text-primary">
                    {{ $t("create_competition.history") }}
                  </h4>
                </div>
                <div>
                  <form class="needs-validation" novalidate="">
                    <div class="row">
                      <div class="col-12">
                        <div class="form-group">
                          <label class="form-label" for="basic-addon-name"
                            >{{ $t("create_competition.history_th") }}
                          </label>
                          <vue-editor
                            v-model="
                              competition.setting_competition_informations
                                .setting_competition_information_history_th
                            "
                          ></vue-editor>
                        </div>
                      </div>
                      <div class="col-12">
                        <div class="form-group">
                          <label class="form-label" for="basic-addon-name">
                            {{ $t("create_competition.history_en") }}
                          </label>
                          <vue-editor
                            v-model="
                              competition.setting_competition_informations
                                .setting_competition_information_history_en
                            "
                          ></vue-editor>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </v-form>
        </div>
        <SocialContactModal
          :socialContact="social_contacts"
          :id="competitionId"
          :dialog.sync="showSocialContactModal"
        />
        <v-snackbar
          v-model="showErrorMessage"
          :value="true"
          color="red"
          timeout="5000"
          top
          rounded="pill"
        >
          <div class="d-flex justify-content-center">
            <strong>{{ error }}</strong>
          </div>
        </v-snackbar>
    </v-form>
  </div>
</template>

<script>
import SocialContactModal from "@/components/Competition/CompetitionSocialContactModal.vue";
import { VueEditor } from "vue2-editor";
import "vue2-dropzone/dist/vue2Dropzone.min.css";
import { mapActions, mapState } from "vuex";
import { format } from "date-fns";

export default {
  name: "AddCompetitionForm",

  components: {
    VueEditor,
    SocialContactModal,
  },
  data() {
    return {
      checkPublic: null,
      competitionId: null,
      showSocialContactModal: false,
      fieldname: "file",
      file: null,
      showErrorMessage: false,
      variable: true,
      activeVariable: false,
    };
  },
  mounted() {
    this.competitionId = this.$route.params.id;
    if (this.competitionId) {
      this.getCompetition(this.competitionId);
    }
  },

  computed: {
    ruleForm() {
      const rules = [];
      rules.push((v) => !!v || `required`);
      return rules;
    },
    ...mapState("competition", [
      "competition",
      "social_contacts",
      "profile_file",
      "success",
      "error",
      "activeStatus",
    ]),
    items() {
      return [
        {
          text: "รายการแข่งขัน",
          disabled: false,
          link: true,
          exact: true,
          to: { name: "competition" },
        },
      ];
    },
    competitionName() {
      return (
        this.competition.setting_competition_informations[
          "setting_competition_information_name_" + this.$t("short_language")
        ] || ""
      );
    },
    profile() {
      if (this.profile_file) return URL.createObjectURL(this.profile_file);
      return this.competition.setting_competition_media_profile_icon;
    },
    activeStatus: {
      get: function () {
        return this.$data.activeVariable;
      },
      set: function (val) {
        this.competition.setting_competition_published = val;
        this.$data.activeVariable = val;
      },
    },
    information() {
      return {
        name: "กีฬาซีเกมส์",
      };
    },

    isFilled() {
      return (
        this.competition &&
        this.competition.setting_competition_informations &&
        this.competition.setting_competition_informations
          .setting_competition_information_code != "" &&
        this.competition.setting_competition_informations
          .setting_competition_information_code != undefined
      );
    },
    levels() {
      return [
        { text: this.$t("competition_level.district"), value: "DISTRICT" },
        { text: this.$t("competition_level.zone"), value: "ZONE" },
        { text: this.$t("competition_level.regional"), value: "CONTINENTAL" },
        { text: this.$t("competition_level.national"), value: "INTERNATIONAL" },
        { text: this.$t("competition_level.continental"), value: "REGIONAL" },
        { text: this.$t("competition_level.global"), value: "GLOBAL" },
      ];
    },
  },

  watch: {
    error() {
      this.showErrorMessage = true;
    },
    success(val) {
      if (val) {
        if (this.competitionId) {
          this.getCompetition(this.competitionId);
        }
      }
    },
    activeStatus() {
      this.setActiveStatus(this.competition.setting_competition_published);
    },

    "competition.active"() {
      this.activeStatus = this.competition.setting_competition_published;
    },
  },

  methods: {
    ...mapActions("competition", [
      "getCompetition",
      "getCompetitionSocialContacts",
      "uploadImageProfile",
      "setProfileFile",
      "setActiveStatus",
    ]),
    onClickSocialContact() {
      this.showSocialContactModal = true;
    },
    filesChange(fieldName, fileList) {
      if (!fileList.length) return;
      // const formData = new FormData();
      Array.from(Array(fileList.length).keys()).map((x) => {
        this.setProfileFile(fileList[x]);
        // formData.append(fieldName, fileList[x], fileList[x].name);
      });

      // this.uploadImageProfile({ competitionId: this.competitionId, formData })
    },
    checkDate(e) {
      const formatDate = format(new Date(e), "dd/MM/yyyy");
      return formatDate;
    },
  },
};
</script>

<style scoped>
.container {
  position: relative;
  width: 60%;
}
.input-file {
  left: 0;
  top: 0;
  opacity: 0; /* invisible but it's there! */
  width: 100%;
  height: 80%;
  min-height: 100px;
  position: absolute;
  cursor: pointer;
}
.camera-icon {
  position: absolute;
  right: 20px;
  top: 20px;
}
</style>
