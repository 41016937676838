var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-dialog',{staticStyle:{"margin-top":"auto"},attrs:{"value":_vm.dialog,"width":"800px","hide-overlay":false},on:{"input":_vm.closeModal}},[_c('v-card',[_c('div',{staticClass:"dialog-wrapper"},[_c('div',{staticClass:"dialog-header"},[_c('div',{staticClass:"dialog-header-title"},[_vm._v(_vm._s(_vm.header[_vm.language]))]),_c('div',{staticClass:"dialog-actions"},[_c('v-btn',{staticClass:"btn-close",attrs:{"color":"primary","plain":""},on:{"click":_vm.closeModal}},[_vm._v(" "+_vm._s(_vm.$t("normal.cancel"))+" ")]),_c('v-btn',{staticClass:"m-l-5px",attrs:{"color":"primary","disabled":!_vm.isEditable},on:{"click":_vm.submit}},[_vm._v(" "+_vm._s(_vm.$t("normal.save"))+" ")])],1)]),_c('div',{staticClass:"dialog-content mx-10 mt-10"},[_c('div',{staticClass:"mx-15 d-flex"},[_c('div',{staticClass:"col-5 text-center d-flex"},[_c('div',[_c('v-img',{attrs:{"src":_vm.data.imageTeam,"width":"100","height":"50","contain":""}})],1),_c('div',[_c('p',{staticClass:"mb-0 pt-0"},[_vm._v(_vm._s(_vm.data.nameTeam))]),_c('p',{class:_vm.data.resultsCompetitor[_vm.language] === 'ชนะ' || _vm.data.resultsCompetitor[_vm.language] === 'Win'
										? 'text-success'
										: _vm.data.resultsCompetitor[_vm.language] === 'แพ้' || _vm.data.resultsCompetitor[_vm.language] === 'Lose'
										? 'text-danger'
										: 'text-body'},[_vm._v(" "+_vm._s(_vm.data.resultsCompetitor[_vm.language])+" ")])])]),_c('div',{staticClass:"col-2 text-center"},[_vm._v(_vm._s(_vm.data.score))]),_c('div',{staticClass:"col-5 text-center d-flex"},[_c('div',[_c('v-img',{attrs:{"src":_vm.data.imageCompetitor,"width":"100","height":"50","contain":""}})],1),_c('div',[_c('p',{staticClass:"mb-0 pt-0"},[_vm._v(_vm._s(_vm.data.nameCompetitor))]),_c('p',{class:_vm.data.resultsTeam[_vm.language] === 'ชนะ' || _vm.data.resultsTeam[_vm.language] === 'Win'
										? 'text-success'
										: _vm.data.resultsTeam[_vm.language] === 'แพ้' || _vm.data.resultsTeam[_vm.language] === 'Lose'
										? 'text-danger'
										: 'text-body'},[_vm._v(" "+_vm._s(_vm.data.resultsTeam[_vm.language])+" ")])])])]),_c('div',[_c('label',[_vm._v(_vm._s(_vm.$t("team.team_remark_th")))]),_c('v-textarea',{attrs:{"auto-grow":"","outlined":"","rows":"5"},model:{value:(_vm.data.remark),callback:function ($$v) {_vm.$set(_vm.data, "remark", $$v)},expression:"data.remark"}})],1),_c('div',[_c('label',[_vm._v(_vm._s(_vm.$t("team.team_remark_en")))]),_c('v-textarea',{attrs:{"auto-grow":"","outlined":"","rows":"5"},model:{value:(_vm.data.remark_en),callback:function ($$v) {_vm.$set(_vm.data, "remark_en", $$v)},expression:"data.remark_en"}})],1),_c('div',{staticClass:"form-group"},[_c('v-checkbox',{attrs:{"label":_vm.$t('team.important_team'),"readonly":!_vm.isEditable,"color":"success","hide-details":""},model:{value:(_vm.data.importantMatch),callback:function ($$v) {_vm.$set(_vm.data, "importantMatch", $$v)},expression:"data.importantMatch"}})],1)])])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }