<template>
	<v-card class='pa-3 competition-round'>
		<v-row class="px-6 d-flex justify-space-between">
			<v-col cols='auto' class='d-flex justify-center align-items-center'>
				<span class='text-primary'>{{ $t('sub_competition.add_round') }}</span>
			</v-col>
			<v-col class='d-flex justify-end'>
				<v-btn text @click="cancelRound">{{ $t('normal.cancel') }}</v-btn>
				<v-btn class='ml-2' color="primary" @click="saveRound">{{ $t('normal.save') }}</v-btn>
			</v-col>
		</v-row>
		<v-row class="px-6 d-flex justify-space-between">
			<v-col cols='6'>
				<div>{{ $t('sub_competition.round_name_th') }}</div>
			</v-col>
			<v-col cols='6'>
				<div>{{ $t('sub_competition.round_name_en') }}</div>
			</v-col>
		</v-row>
		<v-row class="px-6 d-flex justify-space-between">
			<v-col cols='6'>
				<v-text-field
					v-model="nameTH"
					dense
					filled
					disabled
					hide-details="true"
				>
				</v-text-field>
			</v-col>
			<v-col cols='6'>
				<v-text-field
					v-model="nameEN"
					dense
					filled
					disabled
					hide-details="true"
				>
				</v-text-field>
			</v-col>
		</v-row>
		<v-row class="px-6 d-flex flex-row justify-space-between">
			<v-col> {{ $t('sub_competition.description_th') }} </v-col>
		</v-row>
		<v-row class="px-6 d-flex flex-row justify-space-between">
			<v-col>
				<v-textarea
					v-model="descriptionTH"
					dense
          auto-grow
          outlined
          rows="2"
					hide-details="true"
				>
				</v-textarea>
			</v-col>
		</v-row>
		<v-row class="px-6 d-flex flex-row justify-space-between">
			<v-col>{{ $t('sub_competition.description_en') }}</v-col>
		</v-row>
		<v-row class="px-6 d-flex flex-row justify-space-between">
			<v-col>
				<v-textarea
					v-model="descriptionEN"
					dense
          auto-grow
          outlined
          rows="2"
					hide-details="true"
				/>
			</v-col>
		</v-row>
		<div class="form-group px-6 mt-4" v-if="canSelectType">
			<div>รูปแบบรอบการแข่งขัน (Type Section)</div>
			<v-radio-group
				v-model="type"
				row
				dense
			>
				<v-row class="px-6 d-flex flex-row justify-space-between">
					<v-col cols='6' class='d-flex flex-row justify-center align-items-center'>
						<v-radio
							value="KNOCKOUT"
						>
							<template v-slot:label>
								<div class='small-font'>การแข่งทั่วไป</div>
							</template>
						</v-radio>
					</v-col>
					<v-col cols='6' class='d-flex flex-row justify-center align-items-center'>
						<v-radio
							value="GROUP"
						>
							<template v-slot:label>
								<div class='small-font'>การแข่งขันระบบลีกและแบ่งกลุ่ม</div>
							</template>
						</v-radio>
					</v-col>
				</v-row>
			</v-radio-group>
			<v-row class="px-6 d-flex flex-row justify-around">
				<v-col cols='6'
					class='d-flex flex-row justify-center align-items-center'
				>
					<div
						class='pa-4' style="width: 100%;"
						:class="radioSelection('การแข่งทั่วไป')"
					>
						<div class='text-center'>ตัวอย่างบันทึกการแข่งขันทั่วไป</div>
						<v-img src="@/assets/images/match-round.png" height="200" class="my-3" contain></v-img>
					</div>
				</v-col>
				<v-col cols='6'
					class='d-flex flex-row justify-center align-items-center'
				>
					<div
						class='pa-4' style="width: 100%;"
						:class="radioSelection('การแข่งขันระบบลีกและแบ่งกลุ่ม')"
					>
						<div class='text-center'>ตัวอย่างบันทึกการแข่งขันแบบลีกและแบ่งกลุ่ม</div>
						<v-img src="@/assets/images/league-round.png" height="200" class="my-3" contain></v-img>
					</div>
				</v-col>
			</v-row>
		</div>
	</v-card>
</template>

<script>
import { mapActions } from 'vuex'
export default {
	name: 'ModalCompetitionRound',

	props: ['round', 'desc_th', 'desc_en', 'section_id'],

	data() {
		return {
			descriptionTH: this.desc_th || '',
			descriptionEN: this.desc_en || '',
			type: '',
		}
	},

	mounted() {
		this.subCompetitionId = this.$route.params.sid
	},

	watch: {
		desc_en(v) {
			this.descriptionEN = v
		},
		desc_th(v) {
			this.descriptionTH = v
		}
	},

	computed: {
		canSelectType() {
			return !this.section_id && ['THIRD_ROUND', 'SECOND_ROUND'].includes(this.round)
		},
		nameTH() {
			switch (this.round) {
				case 'FINAL': return 'รอบชิงชนะเลิศ'
				case 'SEMI_FINAL': return 'รอบรองชนะเลิศ'
				case 'THIRD_PLACE_MATCH': return 'รอบชิงที่ 3'
				case 'THIRD_ROUND': return 'รอบ 3'
				case 'SECOND_ROUND': return 'รอบ 2'
				default: return 'รอบแบ่งกลุ่ม'
			}
		},
		nameEN() {
			switch (this.round) {
				case 'FINAL': return 'Final'
				case 'SEMI_FINAL': return 'Semi Final'
				case 'THIRD_PLACE_MATCH': return 'Third Place'
				case 'THIRD_ROUND': return 'Third Round'
				case 'SECOND_ROUND': return 'Second Round'
				default: return 'Group Stage'
			}
		},
		roundType() {
			switch (this.round) {
				case 'FINAL': return 'KNOCKOUT'
				case 'SEMI_FINAL': return 'KNOCKOUT'
				case 'THIRD_PLACE_MATCH': return 'KNOCKOUT'
				case 'THIRD_ROUND': return this.type
				case 'SECOND_ROUND': return this.type
				default: return 'GROUP'
			}
		}
	},

	methods: {
		...mapActions('competition_section', ['createOrUpdateSection']),
		async saveRound() {
			let result =  await this.createOrUpdateSection({
				sid: this.subCompetitionId,
				csid: this.section_id,
				payload: {
					section_type: this.roundType,
					competition_section_round_name: this.round,
					competition_section_name_th: this.nameTH,
					competition_section_name_en: this.nameEN,
					competition_section_description_th: this.descriptionTH,
					competition_section_description_en: this.descriptionEN,
					active: true
				}
			})
			if (result.success) {
				if (this.section_id || this.roundType == 'GROUP') {
					this.$emit('close')
				} else {
					this.$emit('close', result.competition_section_id, this.round)
				}
				this.clearData()
			}
		},

		cancelRound() {
			this.$emit('close')
			this.clearData()
		},

		radioSelection(key) {
			return key === this.type ? 'type active' : 'type inactive'
		},

		clearData() {
			this.descriptionTH = ''
			this.descriptionEN = ''
			this.type = ''
		},
	}
}
</script>

<style>
.competition-round,
.small-font {
	font-size: 14px;
}
</style>

<style lang="scss" scoped>
.type.active {
	border: blue 2px solid;
	border-radius: 10px;
	padding: 8px;
}
.type.inactive {
	border: gray 2px solid;
	border-radius: 10px;
	padding: 8px;
}
.row + .row {
	margin-top: 0px;
}
</style>