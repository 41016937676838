<template>
	<v-app id="Dashboard">
		<div class="content app-content h-100">
			<Tab1 />
		</div>
	</v-app>
</template>

<script>
// import Tab1 from '../../components/Dashboard/Tab1.vue'
import Tab1 from "../../components/Dashboard/Tab1.vue";
export default {
	name: "Dashboard",
	components: {
		Tab1,
	},
	mounted() {},
};
</script>

<style></style>
