<template>
  <v-card class="pa-5">
    <template v-if="isAddingTeams">
      <v-row class="d-flex flex-row justify-space-between mb-2">
        <v-col
          cols="auto"
          class="d-flex flex-row justify-center align-items-center"
        >
          <span class="text-primary">{{
            $t("sub_competition.add_sub_group")
          }}</span>
        </v-col>
        <v-col class="d-flex justify-end">
          <v-btn text @click="cancelChangeTeams">
            {{ $t("normal.cancel") }}
          </v-btn>
          <v-btn class="ml-2" color="primary" @click="saveChangeTeams">{{
            $t("normal.save")
          }}</v-btn>
        </v-col>
      </v-row>
      <template v-for="(item, index) in all_teams.data">
        <v-checkbox
          v-model="checkboxes[index]"
          :key="index"
          class="mx-0 mt-0 pa-0"
        >
          <template v-slot:label>
            <v-img
              :src="item.setting_team_media_profile_url"
              max-height="20"
              max-width="20"
              contain
              class="ml-8"
            ></v-img>
            <span class="ml-4" v-if="language === 'th'">{{
              item.setting_team_information_name_th
            }}</span>
            <span class="ml-4" v-else>{{
              item.setting_team_information_name_en
            }}</span>
          </template>
        </v-checkbox>
      </template>
    </template>
    <template v-else>
      <v-row class="d-flex flex-row justify-space-between">
        <v-col
          cols="auto"
          class="d-flex flex-row justify-center align-items-center"
        >
          <span class="text-primary">{{
            $t("sub_competition.add_sub_group")
          }}</span>
        </v-col>
        <v-col class="d-flex justify-end">
          <v-btn text @click="$emit('close')">
            {{ $t("normal.cancel") }}
          </v-btn>
          <v-btn class="ml-2" color="primary" @click="onSaveGroup">{{
            $t("normal.save")
          }}</v-btn>
        </v-col>
      </v-row>
      <div class="mt-4">
        {{ $t("sub_competition.group_name_th") }}
      </div>
      <v-text-field
        v-model="group.competition_group_stage_name"
        dense
        outlined
        hide-details
      ></v-text-field>

      <div class="mt-4">
        {{ $t("sub_competition.group_name_en") }}
      </div>
      <v-text-field
        v-model="group.competition_group_stage_name_en"
        dense
        outlined
        hide-details
      ></v-text-field>
      <div class="outlined-border-corners mt-3 pa-3" v-if="!groupId">
        <div class="d-flex justify-space-between align-center">
          <span>{{ $t("sub_competition.team_in_group") }}</span>
          <v-btn
            color="primary"
            class="text-decoration-underline pa-0 ma-0"
            style="font-size: 16px"
            text
            @click="manageTeamInGroup"
            >{{ $t("sub_competition.add_team_to_group") }}</v-btn
          >
        </div>
        <div
          v-if="group.competition_group_stage_team_join.length == 0"
          class="d-flex flex-column align-center py-6"
        >
          <v-img
            src="@/assets/images/cup.png"
            max-height="100"
            contain
            class="ma-2"
          ></v-img>
          <h4 class="grey--text text--lighten-1">
            {{ $t("competition.not_exist") }}
          </h4>
          <h5 class="grey--text text--lighten-2">
            {{ $t("competition.please_create") }}
          </h5>
        </div>
        <v-data-table
          v-else
          :items="group.competition_group_stage_team_join"
          :headers="headers"
          :hide-default-footer="true"
          :hide-default-header="true"
          item-key="id"
          class="elevation-1"
        >
          <template v-slot:[`item.index`]="{ index }">
            {{ index + 1 }}
          </template>
          <template v-slot:[`item.team`]="{ item }">
            <div class="d-flex">
              <v-img
                :src="item.team_img"
                max-height="128"
                max-width="20"
                contain
                class="ml-8"
              ></v-img>
              <span class="ml-4" v-if="language === 'th'">{{
                item.team_name
              }}</span>
              <span class="ml-4" v-else>{{ item.team_name_en }}</span>
            </div>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-icon small class="pa-3" @click="deleteTeam(item)"
              >fa-trash</v-icon
            >
          </template>
        </v-data-table>
      </div>
    </template>

  </v-card>
</template>

<script>
import { mapActions, mapState } from "vuex";
import MasterDataHelper from "@/helpers/master_data_helper.js";

export default {
  name: "LeagueGroupModal",
  props: ["competitionSectionId", "groupId"],
  data() {
    return {
      isAddingTeams: false,
      checkboxes: [],
      headers: [
        { value: "index", text: "" },
        { value: "team", text: "" },
        { text: "", value: "actions", sortable: false },
      ],
      dialogConfirmDelete: false,
      itemDeleting: null,
      subCompetitionId: null,
      newGroup: {
        competition_group_stage_name: "",
        competition_group_stage_name_en: "",
        competition_group_stage_team_join: [],
      },
    };
  },
  mounted() {
    this.subCompetitionId = this.$route.params.sid;

    let group =
      this.rounds.find((round) => {
        return round.id == this.groupId;
      }) || JSON.parse(JSON.stringify(this.newGroup));
    this.setGroup(group);
  },
  components: {
  },
  computed: {
    ...mapState("sub_competition", ["rounds", "all_teams"]),
    ...mapState("data_model", ["teams", "roundId", "group"]),
  },
  methods: {
    ...mapActions("data_model", [
      "updateGroup",
      "removeTeamFromGroup",
      "clearGroup",
      "setGroup",
    ]),
    ...mapActions("competition_section", ["createOrUpdateGroup"]),
    cancelChangeTeams() {
      this.isAddingTeams = false;
    },
    manageTeamInGroup() {
      this.isAddingTeams = true;
      this.all_teams.data.forEach((item, index) => {
        let team = this.group.competition_group_stage_team_join.find((team) => {
          return team.team_id == item.setting_team_id;
        });
        this.checkboxes[index] = team != undefined;
      });
    },
    saveChangeTeams() {
      this.isAddingTeams = false;
      console.log("saveChangeTeams", this.all_teams.data);
      this.updateGroup(
        this.all_teams.data.filter((team, index) => {
          return this.checkboxes[index];
        })
      );
    },
    async deleteTeam(item) {
      this.itemDeleting = item;
      // this.dialogConfirmDelete = true
      await this.$swal(MasterDataHelper.sweetAlertConfig(this.language)).then(
        async (result) => {
          if (result.value) {
            this.removeTeamFromGroup(this.itemDeleting);
          }
        }
      );
    },
    confirmDeleteItem() {
      this.removeTeamFromGroup(this.itemDeleting);
      this.dialogConfirmDelete = false;
    },
    onSaveGroup() {
      this.createOrUpdateGroup({
        sid: this.subCompetitionId,
        csid: this.competitionSectionId,
        payload: this.group,
      });
      this.$emit("close");
    },
  },
};
</script>

<style scoped>
.outlined-border-corners {
  border: 1px rgb(168, 168, 168) solid;
  border-radius: 6px;
}
</style>