<template>
  <v-card class="my-10">
    <template v-for="section in sections">
      <v-row :key="`header-${section.key}`" class="px-6 py-0">
        <v-col class="d-flex justify-space-between">
          <h6>
            <span class="text-primary">{{ $t(section.translateKey) }}</span>
            <v-icon
              v-if="section.info"
              x-small
              class="mx-2"
              @click="$emit('edit', section.info)"
              >fa-pen</v-icon
            >
            <v-icon
              v-if="section.info"
              x-small
              class="mx-2"
              @click="onDeleteSection(section)"
              >fa-trash</v-icon
            >
            <span v-if="section.info" class="mx-2"
              ><label v-if="language === 'th'">{{
                section.info.competition_section_description_th
              }}</label
              ><label v-else>{{
                section.info.competition_section_description_en
              }}</label></span
            >
          </h6>
          <v-btn
            v-if="canAddMatch(section)"
            color="primary"
            @click="addResultOrSection(section)"
            >+ {{ buttonAddText(section) }}</v-btn
          >
          <template v-else-if="canAddGroup(section)">
            <v-btn color="success" @click="$emit('addGroup', section.info.id)"
              >+ {{ $t("sub_competition.add_group") }}</v-btn
            >
          </template>
        </v-col>
      </v-row>
      <template v-if="section.data.length > 0">
        <div :key="section.key">
          <math-round
            v-if="isKnockOut(section)"
            :key="section.id"
            :round="section.data"
            @updateResult="(value) => $emit('updateResult', value, section.key)"
            @addResult="(value) => $emit('addResult', value, section.key)"
          />
          <league-round
            v-else
            :language="language"
            :key="section.id"
            :round="section.data"
            :comSecId="section.info.id"
            @addGroup="$emit('addGroup')"
            @editGroup="(value) => $emit('editGroup', value)"
            @addResult="(value) => $emit('addGroupResult', value, section.key)"
            @updateResult="
              (value) => $emit('updateGroupResult', value, section.key)
            "
          />
        </div>
      </template>
      <template v-else-if="canShowMatchSlot(section)">
        <math-round
          :key="section.id"
          :round="emptyMatch(section)"
          @updateResult="(value) => $emit('updateResult', value, section.key)"
          @addResult="(value) => $emit('addResult', value, section.key)"
        />
      </template>
      <div
        v-else
        :key="section.key"
        class="
          d-flex
          flex-column
          align-items-center
          justify-content-center
          py-6
        "
        style="min-height: 120px"
      >
        <v-img
          src="@/assets/images/cup.png"
          max-height="40"
          contain
          class="ma-2"
        ></v-img>
        <h4 class="grey--text text--lighten-1">
          {{ $t("competition.not_exist") }}
        </h4>
        <h5 class="grey--text text--lighten-2">
          {{ $t("competition.please_create") }}
        </h5>
      </div>
      <hr :key="`hr-${section.key}`" />
    </template>

  </v-card>
</template>

<script>
import { mapActions, mapState } from "vuex";
import LeagueRound from "./LeagueRound.vue";
import MathRound from "./MathRound.vue";
import { languageService } from "../../../helpers/language";
import MasterDataHelper from "@/helpers/master_data_helper.js";

export default {
  name: "CompetitionRound",

  components: {
    MathRound,
    LeagueRound,
  },

  computed: {
    ...mapState("competition_section", ["sections"]),
  },
  created() {
    this.subscription = languageService.getCurrent().subscribe((lang) => {
      if (lang === "en") {
        this.language = "en";
      } else {
        this.language = "th";
      }
    });
  },
  data() {
    return {
      competitionId: null,
      subCompetitionId: null,
      deletingSection: null,
      dialogConfirmDelete: false,
    };
  },

  methods: {
    ...mapActions("competition_section", [
      "getCompetitionSection",
      "deleteSection",
    ]),
    ...mapActions("sub_competition", ["getAllTeamsInSubCompetition"]),

    isKnockOut(section) {
      return section.info?.section_type === "KNOCKOUT";
    },

    canAddMatch(section) {
      if (section.key === "FINAL" && section.info) {
        return false;
      }
      if (section.key === "SEMI_FINAL" && section.data.length >= 4) {
        return false;
      }
      if (section.key === "THIRD_PLACE_MATCH" && section.info) {
        return false;
      }
      if (section.info?.section_type == "GROUP") {
        return false;
      }
      return true;
    },

    canAddGroup(section) {
      return section.info?.section_type == "GROUP" ? true : false;
    },

    buttonAddText(section) {
      if (
        section.key === "SEMI_FINAL" ||
        section.info?.section_type == "KNOCKOUT"
      ) {
        if (section.info) return this.$t("sub_competition.add_score");
      }
      if (section.key === "THIRD_PLACE_MATCH" && section.info) {
        return false;
      }
      return this.$t("sub_competition.add_round");
    },

    addResultOrSection(section) {
      if (section.key === "SEMI_FINAL" && section.info) {
        this.$emit("addResult", section.info.id, section.key);
      } else if (section.info?.section_type == "KNOCKOUT") {
        this.$emit("addResult", section.info.id, section.key);
      } else {
        this.$emit("add", section.key);
      }
    },

    canShowMatchSlot(section) {
      if (section.info && this.isKnockOut(section)) {
        return true;
      }
      return false;
    },

    emptyMatch(section) {
      return [
        {
          competition_section_id: section.info.id,
          versus_match_date: "-",
          versus_match_team_detail_one_id: {
            versus_match_score: "-",
          },
          versus_match_team_detail_two_id: {
            versus_match_score: "-",
          },
        },
      ];
    },

    async onDeleteSection(section) {
      this.deletingSection = section;
      await this.$swal(MasterDataHelper.sweetAlertConfig(this.language)).then(
        async (result) => {
          if (result.value) {
            let result = await this.deleteSection({
              sid: this.subCompetitionId,
              csid: this.deletingSection.info.id,
            });
            this.dialogConfirmDelete = false;
            if (result.success) {
              this.getCompetitionSection(this.subCompetitionId);
            }
          }
        }
      );
    },

    async confirmDeleteSection() {
      let result = await this.deleteSection({
        sid: this.subCompetitionId,
        csid: this.deletingSection.info.id,
      });
      this.dialogConfirmDelete = false;
      if (result.success) {
        this.getCompetitionSection(this.subCompetitionId);
      }
    },
  },

  mounted() {
    this.competitionId = this.$route.params.cid;
    this.subCompetitionId = this.$route.params.sid;
    this.getCompetitionSection(this.subCompetitionId);
    this.getAllTeamsInSubCompetition({ sid: this.subCompetitionId });
  },
};
</script>