<template>
	<div class="container">
		<div class="mt-5">
			<div class="content-body">				
				<v-toolbar flat>
					<v-tabs
						v-model="tab"
						:show-arrows="false"
						active-class="text-white tab-color"
						hide-slider
					>
						<!-- <v-tabs-slider color="white"></v-tabs-slider>  -->
						<v-tab
							class="app-rounded-top"
							v-for="(item, idx) in tabs"
							:key="idx"
						>
							<v-icon>{{ item.icon }}</v-icon>
							<div class="ml-2">
								<span> {{ $t(item.topic) }} </span> <br />
								<span class="font-small-1"> {{ item.detail }} </span>
							</div>
						</v-tab>
					</v-tabs>
				</v-toolbar>
			</div>
		</div>
		<v-tabs-items class="shadow" v-model="tab">
			<v-tab-item v-for="(item, idx) in tabContents" :key="idx" :value="idx">
				<component ref="tabContent" v-bind:is="item"></component>
			</v-tab-item>
		</v-tabs-items>
	</div>
</template>

<script>
import TabContent from "./TabContent.vue";
export default {
	name: "Tab1",
	mounted() {
	},
	methods: {
		
	},
	computed: {
		isEditable() {
			return this.$store.state.permission.role === "B_ADMIN";
		},
	},
	data() {
		return {
			tab: null,
			tabs: [
				{
					topic: "menu.report",
					icon: "fa-file-alt",
				},
			],
			tabContents: [TabContent],
		};
	},
};
</script>

<style scoped lang="scss">
.tab-color {
	background-color: #0d6efd;
}
.v-tab {
	margin: 0 2px;
	&:not(.v-tab--active) {
		background: #f1f1f5;
	}
}

.app-rounded-top {
	border-top-left-radius: 10px;
	border-top-right-radius: 10px;
}
</style>
