<template>
  <v-card class="match-result-form" data-app>
    <v-row class="border-bottom">
      <v-col cols="3" class="border-right px-6">
        <span class="text-primary">{{
          $t("versus_match.info.match_info")
        }}</span>
        <v-row>
          <v-col cols="6">{{ $t("versus_match.info.match_main_name") }}</v-col>
          <v-col cols="6" v-if="language === 'th'">{{
            competition.setting_competition_informations
              .setting_competition_information_name_th
          }}</v-col>
          <v-col cols="6" v-else>{{
            competition.setting_competition_informations
              .setting_competition_information_name_en
          }}</v-col>
        </v-row>
        <v-row>
          <v-col cols="6">{{ $t("versus_match.info.match_level") }}</v-col>
          <v-col cols="6">{{
            competitionLevel(competition.setting_competition_level)[language]
          }}</v-col>
        </v-row>
        <v-row>
          <v-col cols="6">{{ $t("versus_match.info.main_sub_name") }}</v-col>
          <v-col cols="6" v-if="language === 'th'">{{
            sub_competition.setting_sub_competition_name_th
          }}</v-col>
          <v-col cols="6" v-else>{{
            sub_competition.setting_sub_competition_name_en
          }}</v-col>
        </v-row>
        <v-row>
          <v-col cols="6">{{ $t("versus_match.info.match_round") }}</v-col>
          <v-col cols="6">{{ sectionName(versus.skey)[language] }}</v-col>
        </v-row>
      </v-col>
      <v-col cols="9" class="px-10 pt-6">
        <v-row>
          <v-col cols="4" class="py-0"
            >{{ $t("versus_match.info.match_date")
            }}<span class="red--text">*</span></v-col
          >
          <v-col cols="4" class="py-0"
            >{{ $t("versus_match.info.match_start_end_date")
            }}<span class="red--text">*</span></v-col
          >
          <v-col cols="4" class="py-0"
            >{{ $t("versus_match.info.match_stadium")
            }}<span class="red--text">*</span></v-col
          >
        </v-row>
        <v-row>
          <v-col cols="4" class="py-0">
            <v-menu
              ref="menu"
              :close-on-content-click="false"
              bottom
              left
              offset-y
              :offset-x="false"
              transition="scale-transition"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-bind="attrs"
                  v-on="on"
                  :value="matchDate"
                  dense
                  outlined
                  hide-details
                  :placeholder="$t('normal.date_format')"
                  append-icon="fa-calendar"
                ></v-text-field>
              </template>
              <v-date-picker
                ref="picker"
                min="1950-01-01"
                locale="us-us"
                :max="maxSelectDate"
                v-model="resultForm.versus_match_date"
              ></v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="4" class="py-0">
            <v-row>
              <v-col cols="5">
                <div class="md-form md-outline">
                  <input
                    v-model="resultForm.versus_match_start_at"
                    type="time"
                    id="default-picker"
                    class="form-control"
                  />
                </div>
              </v-col>
              <v-col cols="2">
                <h1>-</h1>
              </v-col>
              <v-col cols="5">
                <div class="md-form md-outline">
                  <input
                    v-model="resultForm.versus_match_end_at"
                    type="time"
                    id="default-picker"
                    class="form-control"
                  />
                </div>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="4" class="py-0">
            <v-select
              :placeholder="$t('normal.please_select')"
              v-model="resultForm.setting_stadiums"
              :items="stadiums"
              dense
              outlined
              item-value="value"
              :item-text="'text_' + language"
            ></v-select>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="4" class="py-0"
            >{{ $t("versus_match.info.match_host")
            }}<span class="red--text">*</span></v-col
          >
          <v-col cols="4" class="py-0"
            >{{ $t("versus_match.info.match_number_people")
            }}<span class="red--text">*</span></v-col
          >
        </v-row>
        <v-row>
          <v-col cols="4" class="py-0">
            <v-select
              :placeholder="$t('normal.please_select')"
              v-model="resultForm.versus_match_stadium_holder.id"
              :items="availableTeams"
              dense
              outlined
              item-text="text_th"
              @change="changeHolder"
            >
              <template v-slot:selection="{ item }">
                <v-img
                  :src="item.image"
                  max-height="20"
                  max-width="20"
                  contain
                  class="ma-2"
                ></v-img>
                <span
                  ><div v-if="language === 'th'">{{ item.text_th }}</div>
                  <div v-else>{{ item.text_en }}</div></span
                >
              </template>
              <template v-slot:item="{ item }">
                <v-img
                  :src="item.image"
                  max-height="20"
                  max-width="20"
                  contain
                  class="ma-2"
                ></v-img>
                <span
                  ><div v-if="language === 'th'">{{ item.text_th }}</div>
                  <div v-else>{{ item.text_en }}</div></span
                >
              </template>
            </v-select>
          </v-col>
          <v-col cols="4" class="py-0">
            <v-text-field
              type="number"
              v-model.number="resultForm.versus_match_visitors"
              dense
              outlined
              hide-details
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="4" class="py-0"
            >{{ $t("versus_match.info.referee") }}1</v-col
          >
          <v-col cols="4" class="py-0"
            >{{ $t("versus_match.info.referee") }}2</v-col
          >
          <v-col cols="4" class="py-0"
            >{{ $t("versus_match.info.referee") }}3</v-col
          >
        </v-row>
        <v-row>
          <v-col cols="4" class="py-0">
            <v-select
              clearable
              :placeholder="$t('normal.please_select')"
              v-model="resultForm.versus_match_referee.versus_match_referee_one"
              :items="referees"
              dense
              :disabled="disabledReferee[0]"
              :item-text="'text_' + language"
              outlined
            ></v-select>
          </v-col>
          <v-col cols="4" class="py-0">
            <v-select
              clearable
              :placeholder="$t('normal.please_select')"
              v-model="resultForm.versus_match_referee.versus_match_referee_two"
              :items="referees"
              :disabled="disabledReferee[1]"
              :item-text="'text_' + language"
              dense
              outlined
            ></v-select>
          </v-col>
          <v-col cols="4" class="py-0">
            <v-select
              clearable
              :placeholder="$t('normal.please_select')"
              v-model="
                resultForm.versus_match_referee.versus_match_referee_three
              "
              :disabled="disabledReferee[2]"
              :items="referees"
              :item-text="'text_' + language"
              dense
              outlined
            ></v-select>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="4" class="py-0"
            >{{ $t("versus_match.info.referee") }}4</v-col
          >
          <v-col cols="4" class="py-0"
            >{{ $t("versus_match.info.referee") }}5</v-col
          >
          <v-col cols="4" class="py-0"
            >{{ $t("versus_match.info.referee") }}6</v-col
          >
        </v-row>
        <v-row>
          <v-col cols="4" class="py-0">
            <v-select
              clearable
              :placeholder="$t('normal.please_select')"
              v-model="
                resultForm.versus_match_referee.versus_match_referee_four
              "
              :disabled="disabledReferee[3]"
              :item-text="'text_' + language"
              :items="referees"
              dense
              outlined
            ></v-select>
          </v-col>
          <v-col cols="4" class="py-0">
            <v-select
              clearable
              :placeholder="$t('normal.please_select')"
              v-model="
                resultForm.versus_match_referee.versus_match_referee_five
              "
              :disabled="disabledReferee[4]"
              :item-text="'text_' + language"
              :items="referees"
              dense
              outlined
            ></v-select>
          </v-col>
          <v-col cols="4" class="py-0">
            <v-select
              clearable
              :placeholder="$t('normal.please_select')"
              v-model="resultForm.versus_match_referee.versus_match_referee_six"
              :items="referees"
              :disabled="disabledReferee[5]"
              :item-text="'text_' + language"
              dense
              outlined
            ></v-select>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row class="border-bottom py-2">
      <v-col cols="4" class="py-0" offset="1">
        <v-select
          :placeholder="$t('normal.please_select')"
          v-model="resultForm.versus_match_team_left.versus_match_team_id"
          :items="availableTeams"
          dense
          outlined
          :disabled="homeDisabled"
          @change="onHomeTeamChange"
        >
          <template v-slot:selection="{ item }">
            <v-img
              :src="item.image"
              max-height="20"
              max-width="20"
              contain
              class="ma-2"
            ></v-img>
            <span
              ><div v-if="language === 'th'">{{ item.text_th }}</div>
              <div v-else>{{ item.text_en }}</div></span
            >
          </template>
          <template v-slot:item="{ item }">
            <v-img
              :src="item.image"
              max-height="20"
              max-width="20"
              contain
              class="ma-2"
            ></v-img>
            <span
              ><div v-if="language === 'th'">{{ item.text_th }}</div>
              <div v-else>{{ item.text_en }}</div></span
            >
          </template>
        </v-select>
      </v-col>
      <v-col cols="2" class="d-flex justify-center py-0">
        <v-text-field
          type="number"
          v-model="resultForm.versus_match_team_left.versus_match_score"
          dense
          outlined
          hide-details
          placeholder="-"
          class="centered-input"
        ></v-text-field>
        <h2 class="mx-2">-</h2>
        <v-text-field
          type="number"
          v-model="resultForm.versus_match_team_right.versus_match_score"
          dense
          outlined
          hide-details
          placeholder="-"
          class="centered-input"
        ></v-text-field>
      </v-col>
      <v-col cols="4" class="py-0">
        <v-select
          :placeholder="$t('normal.please_select')"
          v-model="resultForm.versus_match_team_right.versus_match_team_id"
          :items="availableTeams"
          dense
          outlined
          :disabled="awayDisabled"
          @change="onAwayTeamChange"
        >
          <template v-slot:selection="{ item }">
            <v-img
              :src="item.image"
              max-height="20"
              max-width="20"
              contain
              class="ma-2"
            ></v-img>
            <span
              ><div v-if="language === 'th'">{{ item.text_th }}</div>
              <div v-else>{{ item.text_en }}</div></span
            >
          </template>
          <template v-slot:item="{ item }">
            <v-img
              :src="item.image"
              max-height="20"
              max-width="20"
              contain
              class="ma-2"
            ></v-img>
            <span
              ><div v-if="language === 'th'">{{ item.text_th }}</div>
              <div v-else>{{ item.text_en }}</div></span
            >
          </template>
        </v-select>
      </v-col>
    </v-row>
    <v-row class="py-2">
      <v-col class="py-0" cols="5" offset="1">
        {{ $t("versus_match.info.match_manager") }}
      </v-col>
      <v-col class="py-0" cols="5">
        {{ $t("versus_match.info.match_manager") }}
      </v-col>
    </v-row>
    <v-row>
      <v-col class="py-0" cols="5" offset="1">
        <v-select
          v-if="left_staff.length > 0"
          :placeholder="$t('normal.please_select')"
          v-model="
            resultForm.versus_match_team_left.versus_match_team_detail_staff
          "
          :items="left_staff"
          dense
          outlined
        >
          <template v-slot:selection="{ item }">
            <v-avatar size="20">
              <v-img
                v-if="item.staffImage"
                :src="item.staffImage"
                contain
                class="ma-2"
              ></v-img>
              <v-icon v-else>fa-user</v-icon>
            </v-avatar>
            <span class="ml-2"
              ><div v-if="language === 'th'">{{ item.staffName }}</div>
              <div v-else>{{ item.staffNameEn }}</div></span
            >
          </template>
          <template v-slot:item="{ item }">
            <v-avatar size="20">
              <v-img
                v-if="item.staffImage"
                :src="item.staffImage"
                contain
                class="ma-2"
              ></v-img>
              <v-icon v-else>fa-user</v-icon>
            </v-avatar>
            <span class="ml-2"
              ><div v-if="language === 'th'">{{ item.staffName }}</div>
              <div v-else>{{ item.staffNameEn }}</div></span
            >
          </template>
        </v-select>
        <v-text-field
          v-else-if="
            resultForm.versus_match_team_left.versus_match_team_detail_staff
          "
          v-model="
            resultForm.versus_match_team_left.versus_match_team_detail_staff
              .staffName
          "
          dense
          outlined
          hide-details
          class="mb-3"
        ></v-text-field>
        <v-text-field
          v-else
          v-model="
            resultForm.versus_match_team_left.versus_match_team_detail_new_staff
          "
          dense
          outlined
          hide-details
          class="mb-3"
        ></v-text-field>
      </v-col>
      <v-col class="py-0" cols="5">
        <v-select
          v-if="right_staff.length > 0"
          :placeholder="$t('normal.please_select')"
          v-model="
            resultForm.versus_match_team_right.versus_match_team_detail_staff
          "
          :items="right_staff"
          dense
          outlined
        >
          <template v-slot:selection="{ item }">
            <v-avatar size="20">
              <v-img
                v-if="item.staffImage"
                :src="item.staffImage"
                contain
                class="ma-2"
              ></v-img>
              <v-icon v-else>fa-user</v-icon>
            </v-avatar>
            <span class="ml-2"
              ><div v-if="language === 'th'">{{ item.staffName }}</div>
              <div v-else>{{ item.staffNameEn }}</div></span
            >
          </template>
          <template v-slot:item="{ item }">
            <v-avatar size="20">
              <v-img
                v-if="item.staffImage"
                :src="item.staffImage"
                contain
                class="ma-2"
              ></v-img>
              <v-icon v-else>fa-user</v-icon>
            </v-avatar>
            <span class="ml-2"
              ><div v-if="language === 'th'">{{ item.staffName }}</div>
              <div v-else>{{ item.staffNameEn }}</div></span
            >
          </template>
        </v-select>
        <v-text-field
          v-else-if="
            resultForm.versus_match_team_right.versus_match_team_detail_staff
          "
          v-model="
            resultForm.versus_match_team_right.versus_match_team_detail_staff
              .staffName
          "
          dense
          outlined
          hide-details
          class="mb-3"
        ></v-text-field>
        <v-text-field
          v-else
          v-model="
            resultForm.versus_match_team_right
              .versus_match_team_detail_new_staff
          "
          dense
          outlined
          hide-details
          class="mb-3"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="6" class="border-right px-6">
        <player-management
          :players="left_player"
          :team="resultForm.versus_match_team_left"
          side="home"
        />
      </v-col>
      <v-col cols="6" class="border-left px-6">
        <player-management
          :players="right_player"
          :team="resultForm.versus_match_team_right"
          side="away"
        />
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import PlayerManagement from "./PlayerManagement.vue";
import { format } from "date-fns";
import { mapActions, mapState } from "vuex";
import { languageService } from "../../../helpers/language";

export default {
  name: "ResultForm",

  props: ["versus"],

  components: {
    PlayerManagement,
  },
  created() {
    this.subscription = languageService.getCurrent().subscribe((lang) => {
      if (lang === "en") {
        this.language = "en";
      } else {
        this.language = "th";
      }
    });
  },
  data() {
    return {
      disabledReferee: [false, true, true, true, true, true],
      language: "th",
      maxSelectDate: format(new Date(), "yyyy-MM-dd"),
      subCompetitionId: null,
    };
  },

  async mounted() {
    this.subCompetitionId = this.$route.params.sid;

    this.getMatchMaster({ sid: this.subCompetitionId, csid: this.versus.csid });
    this.getPlayerPositions();

    if (this.versus.mid || this.versus.gid) {
      await this.getVersusMatchResult(this.versus);
    }

    if (this.versus.left_id) {
      console.log("this.versus.left_id", this.versus.left_id);
      this.resultForm.versus_match_team_left.versus_match_team_id = `${this.versus.left_id}`;
      if (!this.versus.mid) {
        this.getTeamData({
          teamId: this.versus.left_id,
          csid: this.versus.csid,
          side: "left",
        });
      }
    }

    if (this.versus.right_id) {
      this.resultForm.versus_match_team_right.versus_match_team_id = `${this.versus.right_id}`;
      if (!this.versus.mid) {
        this.getTeamData({
          teamId: this.versus.right_id,
          csid: this.versus.csid,
          side: "right",
        });
      }
    }
    // if(this.versus)
  },

  watch: {
    "versus.mid"() {
      this.getVersusMatchResult(this.versus);
    },
    "versus.gid"() {
      this.getVersusMatchResult(this.versus);
    },
    "resultForm.versus_match_referee.versus_match_referee_one"() {

      if (this.resultForm.versus_match_referee.versus_match_referee_one) {
        this.$data.disabledReferee[1] = false;
      } else {
        this.$data.disabledReferee[1] = true;
      }
      if (!this.resultForm.versus_match_referee.versus_match_referee_one) {
        if (this.resultForm.versus_match_referee.versus_match_referee_two) {
          this.resultForm.versus_match_referee.versus_match_referee_one =
            this.resultForm.versus_match_referee.versus_match_referee_two;
          this.resultForm.versus_match_referee.versus_match_referee_two =
            this.resultForm.versus_match_referee.versus_match_referee_three;
          this.resultForm.versus_match_referee.versus_match_referee_three =
            this.resultForm.versus_match_referee.versus_match_referee_four;
          this.resultForm.versus_match_referee.versus_match_referee_four =
            this.resultForm.versus_match_referee.versus_match_referee_five;
          this.resultForm.versus_match_referee.versus_match_referee_five =
            this.resultForm.versus_match_referee.versus_match_referee_six;
          this.resultForm.versus_match_referee.versus_match_referee_six = null;
        }
      }
    },
    "resultForm.versus_match_referee.versus_match_referee_two"() {

      if (this.resultForm.versus_match_referee.versus_match_referee_two) {
        this.$data.disabledReferee[2] = false;
      } else {
        this.$data.disabledReferee[2] = true;
      }
      if (!this.resultForm.versus_match_referee.versus_match_referee_two) {
        if (this.resultForm.versus_match_referee.versus_match_referee_three) {
          this.resultForm.versus_match_referee.versus_match_referee_two =
            this.resultForm.versus_match_referee.versus_match_referee_three;
          this.resultForm.versus_match_referee.versus_match_referee_three =
            this.resultForm.versus_match_referee.versus_match_referee_four;
          this.resultForm.versus_match_referee.versus_match_referee_four =
            this.resultForm.versus_match_referee.versus_match_referee_five;
          this.resultForm.versus_match_referee.versus_match_referee_five =
            this.resultForm.versus_match_referee.versus_match_referee_six;
          this.resultForm.versus_match_referee.versus_match_referee_six = null;
        }
      }
    },
    "resultForm.versus_match_referee.versus_match_referee_three"() {
      if (this.resultForm.versus_match_referee.versus_match_referee_three) {
        this.$data.disabledReferee[3] = false;
      } else {
        this.$data.disabledReferee[3] = true;
      }

      if (!this.resultForm.versus_match_referee.versus_match_referee_three) {
        if (this.resultForm.versus_match_referee.versus_match_referee_four) {
          this.resultForm.versus_match_referee.versus_match_referee_three =
            this.resultForm.versus_match_referee.versus_match_referee_four;
          this.resultForm.versus_match_referee.versus_match_referee_four =
            this.resultForm.versus_match_referee.versus_match_referee_five;
          this.resultForm.versus_match_referee.versus_match_referee_five =
            this.resultForm.versus_match_referee.versus_match_referee_six;
          this.resultForm.versus_match_referee.versus_match_referee_six = null;
        }
      }
    },
    "resultForm.versus_match_referee.versus_match_referee_four"() {
      if (this.resultForm.versus_match_referee.versus_match_referee_four) {
        this.$data.disabledReferee[4] = false;
      } else {
        this.$data.disabledReferee[4] = true;
      }

      if (!this.resultForm.versus_match_referee.versus_match_referee_four) {
        if (this.resultForm.versus_match_referee.versus_match_referee_five) {
          this.resultForm.versus_match_referee.versus_match_referee_four =
            this.resultForm.versus_match_referee.versus_match_referee_five;
          this.resultForm.versus_match_referee.versus_match_referee_five =
            this.resultForm.versus_match_referee.versus_match_referee_six;
          this.resultForm.versus_match_referee.versus_match_referee_six = null;
        }
      }
    },
    "resultForm.versus_match_referee.versus_match_referee_five"() {

      if (this.resultForm.versus_match_referee.versus_match_referee_five) {
        this.$data.disabledReferee[5] = false;
      } else {
        this.$data.disabledReferee[5] = true;
      }

      if (!this.resultForm.versus_match_referee.versus_match_referee_five) {
        if (this.resultForm.versus_match_referee.versus_match_referee_six) {
          this.resultForm.versus_match_referee.versus_match_referee_five =
            this.resultForm.versus_match_referee.versus_match_referee_six;
          this.resultForm.versus_match_referee.versus_match_referee_six = null;
        }
      }
    },
  },

  computed: {
    ...mapState("competition", ["competition"]),
    ...mapState("sub_competition", ["sub_competition"]),
    ...mapState("versus_match", [
      "teams",
      "referees",
      "stadiums",
      "left_player",
      "right_player",
      "left_staff",
      "right_staff",
    ]),
    ...mapState("match", ["resultForm"]),

    homeDisabled() {
      return (
        this.versus.gid != undefined &&
        (this.versus.mid != undefined || this.versus.left_id != undefined)
      );
    },
    awayDisabled() {
      return (
        this.versus.gid != undefined &&
        (this.versus.mid != undefined || this.versus.right_id != undefined)
      );
    },

    availableTeams() {
      return this.teams.map((t) => {
        return {
          value: t.teamId,
          image: t.teamImage,
          text_th: t.teamName,
          text_en: t.teamNameEn,
        };
      });
    },

    matchDate() {
      if (!this.resultForm.versus_match_date) {
        return null;
      }
      return format(new Date(this.resultForm.versus_match_date), "dd/MM/yy");
    },
    timeStart() {
      return this.resultForm.versus_match_start_at;
    },
    timeEnd() {
      return this.resultForm.versus_match_end_at;
    },
  },
  methods: {
    ...mapActions("versus_match", [
      "getMatchMaster",
      "getTeamData",
      "getVersusMatchResult",
    ]),
    ...mapActions("match", ["getPlayerPositions"]),

    onHomeTeamChange(val) {
      let team = this.teams.find((team) => team.teamId == val);
      if (team) {
        this.resultForm.versus_match_team_left.versus_match_team_image =
          team.teamImage;
        this.resultForm.versus_match_team_left.versus_match_team_name =
          team.teamName;
        this.resultForm.versus_match_team_left.versus_match_team_name_en =
          team.teamNameEn;
      }
      this.getTeamData({ teamId: val, csid: this.versus.csid, side: "left" });
    },
    onAwayTeamChange(val) {
      let team = this.teams.find((team) => team.teamId == val);
      if (team) {
        this.resultForm.versus_match_team_right.versus_match_team_image =
          team.teamImage;
        this.resultForm.versus_match_team_right.versus_match_team_name =
          team.teamName;
        this.resultForm.versus_match_team_right.versus_match_team_name_en =
          team.teamNameEn;
      }
      this.getTeamData({ teamId: val, csid: this.versus.csid, side: "right" });
    },

    changeHolder(val) {
      let team = this.teams.find((team) => team.teamId == val);
      if (team) {
        // this.resultForm.versus_match_team_right.versus_match_team_image = team.teamImage
        this.resultForm.versus_match_stadium_holder.image = team.teamImage;
        this.resultForm.versus_match_stadium_holder.name_th = team.teamName;
        this.resultForm.versus_match_stadium_holder.name_en = team.teamNameEn;
      }
    },
    competitionLevel(level) {
      switch (level) {
        case "DISTRICT":
          return {
            th: "ระดับจังหวัด",
            en: "District",
          };
        case "ZONE":
          return {
            th: "ระดับภาค",
            en: "Zone",
          };
        case "REGIONAL":
          return {
            th: "ระดับภูมิภาค",
            en: "Regional",
          };
        case "NATIONAL":
          return {
            th: "ระดับประเทศ",
            en: "National",
          };
        case "CONTINENTAL":
          return {
            th: "ระดับทวีป",
            en: "Continental",
          };
        case "INTERNATIONAL":
          return {
            th: "ระดับนานาชาติ",
            en: "International",
          };

        case "GLOBAL":
          return {
            th: "ระดับโลก",
            en: "Global",
          };
        default:
          return {
            th: "",
            en: "",
          };
      }
    },
    sectionName(skey) {
      switch (skey) {
        case "FINAL":
          return {
            th: "รอบชิงชนะเลิศ",
            en: "Final",
          };
        case "SEMI_FINAL":
          return {
            th: "รอบรองชนะเลิศ",
            en: "Semi Final",
          };
        case "THIRD_PLACE_MATCH":
          return {
            th: "รอบชิงที่ 3",
            en: "Third Place Match",
          };
        case "THIRD_ROUND":
          return {
            th: "รอบ 3",
            en: "Third Round",
          };
        case "SECOND_ROUND":
          return {
            th: "รอบ 2",
            en: "Second Round",
          };
        case "GROUP_STAGE":
          return {
            th: "รอบแบ่งกลุ่ม",
            en: "Group Stage",
          };
        default:
          return {
            th: "",
            en: "",
          };
      }
    },
  },
};
</script>

<style scoped>
.match-result-form {
  font-size: 14px;
}

.centered-input >>> input {
  text-align: center;
}
</style>