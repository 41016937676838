<template>
  <div id="headder">
    <nav
      class="
        header-navbar
        navbar navbar-expand-lg
        align-items-center
        navbar-light navbar-shadow
        fixed-top
      "
    >
      <div class="navbar-container d-flex">
        <div class="bookmark-wrapper d-flex align-items-center">
          <ul class="nav navbar-nav d-xl-none">
            <li class="nav-item">
              <a class="nav-link menu-toggle" href="javascript:void(0);">
                <v-icon>fa-bars</v-icon>
              </a>
            </li>
          </ul>
        </div>
        <ul class="nav navbar-nav align-items-center ml-auto">
          <li class="nav-item dropdown dropdown-language mr-25">
            <a
              class="nav-link"
              href="javascript:void(0);"
              data-toggle="dropdown"
              ><v-icon class="mr-1" v-if="this.$i18n.locale === 'th'"
                >$thaiIcon</v-icon
              >
              <v-icon class="mr-1" v-else>$englishIcon</v-icon
              >{{ $t("language") }}</a
            >
            <ul class="dropdown-menu dropdown-menu-right">
              <li class="dropdown-menu-header" @click="changeLanguage">
                <div class="dropdown-header d-flex">
                  <v-icon v-if="this.$i18n.locale !== 'th'">$thaiIcon</v-icon>
                  <v-icon v-else>$englishIcon</v-icon>
                  <h4 class="notification-title mb-0 ml-1 mr-auto">
                    <!-- {{ this.$data.newLanguage  }} -->
                    {{ $t("new_language") }}
                  </h4>
                </div>
              </li>
            </ul>
          </li>
          <!-- <li class="nav-item dropdown dropdown-notification mr-25"><a class="nav-link" href="javascript:void(0);" data-toggle="dropdown"><v-icon>fa-bell</v-icon><span class="badge badge-pill badge-danger badge-up">5</span></a>
                    <ul class="dropdown-menu dropdown-menu-media dropdown-menu-right">
                        <li class="dropdown-menu-header">
                            <div class="dropdown-header d-flex">
                                <h4 class="notification-title mb-0 mr-auto">แจ้งเตือน</h4>
                                <div class="badge badge-pill badge-light-primary">6 New</div>
                            </div>
                        </li>

                    </ul>
                </li> -->
          <li class="nav-item dropdown dropdown-user">
            <a
              class="nav-link dropdown-toggle dropdown-user-link"
              href="javascript:void(0);"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <div class="user-nav d-sm-flex d-none">
                <span class="user-name font-weight-bolder">{{
                  users.name
                }}</span
                ><span class="user-status">{{ users.posit }}</span>
              </div>
              <!-- <span class="avatar"
                ><img
                  class="round"
                  src="@/assets/images/img_avatar.png"
                  alt="avatar"
                  height="40"
                  width="40"/><span class="avatar-status-online"></span
              ></span> -->
            </a>
            <!-- <div class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdown-user"><a class="dropdown-item" href="page-profile.html"><i class="mr-50" data-feather="user"></i> Profile</a><a class="dropdown-item" href="app-email.html"><i class="mr-50" data-feather="mail"></i> Inbox</a><a class="dropdown-item" href="app-todo.html"><i class="mr-50" data-feather="check-square"></i> Task</a><a class="dropdown-item" href="app-chat.html"><i class="mr-50" data-feather="message-square"></i> Chats</a>
                        <div class="dropdown-divider"></div><a class="dropdown-item" href="page-account-settings.html"><i class="mr-50" data-feather="settings"></i> Settings</a><a class="dropdown-item" href="page-pricing.html"><i class="mr-50" data-feather="credit-card"></i> Pricing</a><a class="dropdown-item" href="page-faq.html"><i class="mr-50" data-feather="help-circle"></i> FAQ</a><a class="dropdown-item" href="page-auth-login-v2.html"><i class="mr-50" data-feather="power"></i> Logout</a>
                    </div> -->
          </li>
        </ul>
      </div>
    </nav>
  </div>
</template>

<script>
import { languageService } from "../../helpers/language";

export default {
  name: "headder",
  mounted() {
    //console.log(this.users.name)
  },
  computed: {
    users() {
      return this.$store.state.user.user;
    }
  },
  methods: {
    changeLanguage() {
      if (localStorage.getItem("language") === "th") {
        this.$i18n.locale = "en";
      } else {
        this.$i18n.locale = "th";
      }
      languageService.setCurrent(this.$i18n.locale);
    }
  }
  // data() {
  // 	return {
  // 		language: "ภาษาไทย",
  // 		newLanguage: "English",
  // 		// user : {
  // 		//     name : "นายทดสอบระบบ ทดสอบระบบ",
  // 		//     posit : "Admin",
  // 		// },
  // 	};
  // },
};
</script>

<style scoped>
/*@import '../../assets/app-assets/vendors/css/vendors.min.css'; */
.dropdown-menu {
  width: 200px !important;
  cursor: pointer;
}
</style>
