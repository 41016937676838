<template>
  <div class="row justify-content-center">
    <div class="col-xl-9 col-12">
      <div class="card shadow-none">
        <div class="card-body">
          <section class="py-5 border-bottom">
            <h4 class="card-title text-primary">
              {{ $t("player.athlete_info.image_website") }}
            </h4>
            <div class="row">
              <div class="col-12 col-md-5 py-0 order-0"></div>
              <div class="col-12 col-md-7 py-0 order-md-2 order-3">
                <p class="text-right card-text mb-0">
                  {{ $t("player.athlete_info.perview") }}
                </p>
              </div>
              <div class="col-12 col-md-5 order-2 order-md-3">
                <p class="text-right card-text mb-0"></p>
                <div class="row">
                  <div class="col-6">
                    <div class="position-relative">
                      <img
                        v-if="item2.imageUrl2"
                        :src="item2.imageUrl2"
                        width="178"
                        height="200"
                        alt="Alternative "
                        class="d-flex justify-content-center"
                      />
                      <img
                        v-else-if="this.$data.dataImage"
                        :src="this.$data.dataImage"
                        width="178"
                        height="200"
                        alt="Alternative "
                        class="d-flex justify-content-center"
                      />

                      <img
                        v-else
                        src="@/assets/images/person.png"
                        width="178"
                        height="200"
                        alt="Alternative Text"
                        class="d-flex justify-content-center"
                      />
                      <div class="image-upload" @change="onChangeImage2">
                        <label for="file-input2">
                          <v-icon class="uploadImage" for="file-input">
                            fa-camera
                          </v-icon>
                        </label>
                        <input
                          class="selectFile"
                          id="file-input2"
                          type="file"
                          accept="image/*"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="col-6 d-flex align-items-end">
                    <div class="card-text font-small-1 ml-3 bottom-align-text">
                      <p class="mb-0">
                        {{ $t("player.athlete_info.example_preview_1") }}<br />
                        {{ $t("player.athlete_info.example_preview_2") }}<br />
                        {{ $t("player.athlete_info.example_preview_3") }}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-7 col-12 order-4">
                <div
                  class="position-relative h-100"
                  :class="
                    this.$data.isPlayerRoute ? 'show-image' : 'show-image-staff'
                  "
                >
                  <img
                    v-if="item2.imageUrl2"
                    :src="item2.imageUrl2"
                    width="20%"
                    height="auto"
                    alt="Alternative "
                    :style="
                      this.$data.isPlayerRoute
                        ? 'bottom: 0; left: 40%'
                        : 'bottom:0%;left:25%'
                    "
                    class="position-absolute"
                  />
                  <img
                    v-else-if="this.$data.dataImage"
                    :src="this.$data.dataImage"
                    width="20%"
                    height="auto"
                    :style="
                      this.$data.isPlayerRoute
                        ? 'bottom: 0; left: 40%'
                        : 'bottom:0%;left:25%'
                    "
                    class="position-absolute"
                  />
                  <img
                    v-else
                    src="@/assets/images/person.png"
                    width="20%"
                    height="auto"
                    alt="Alternative "
                    :style="
                      this.$data.isPlayerRoute
                        ? 'bottom: 0; left: 40%'
                        : 'bottom:0%;left:25%'
                    "
                    class="position-absolute"
                  />
                </div>
              </div>
            </div>
          </section>
          <section class="py-5 border-bottom">
            <template v-if="isPlayerRoute">
              <h4 class="card-title text-primary">
                {{ label.specificationTitle[language] }}
              </h4>
              <div class="row">
                <div
                  v-for="(item, idx) in typeSport"
                  :key="idx"
                  class="col-12 col-md-4"
                >
                  <div class="form-group">
                    <label class="form-label">{{ item[language] }}</label>
                    <v-select
                      hide-details
                      :placeholder="$t('normal.please_select')"
                      dense
                      v-model="
                        specificationForm.global_config_sport_categories[idx]
                      "
                      @change="handleChangeSport"
                      :disabled="item.disable"
                      :readonly="!isEditable"
                      :items="sportCategories"
                      :item-text="
                        'global_config_sport_category_name_' + language
                      "
                      clearable
                      item-value="id"
                      outlined
                    ></v-select>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-12">
                  <div class="form-group">
                    <label>{{
                      $t("player.athlete_info.player_position")
                    }}</label>
                    <v-radio-group
                      row
                      v-model="
                        specificationForm.account_specification_main_position
                      "
                      :readonly="!isEditable"
                    >
                      <v-radio
                        v-for="(item, idx) in mainPositions"
                        :key="idx"
                        on-icon="fa-circle"
                        :value="item.value"
                        :label="item.label[language]"
                      ></v-radio>
                    </v-radio-group>
                  </div>
                </div>
              </div>
              <div class="row">
                <div
                  class="col-12 col-md-4"
                  v-for="(item, idx) in positionSport"
                  :key="idx"
                >
                  <div class="form-group">
                    <label class="form-label">{{ item[language] }}</label>
                    <v-select
                      :items="positions"
                      item-value="id"
                      :disabled="item.disable"
                      :item-text="'global_config_position_name_' + language"
                      v-model="specificationForm.global_config_positions[idx]"
                      :readonly="!isEditable"
                      :placeholder="$t('normal.please_select')"
                      clearable
                      dense
                      @change="handleChangePosition"
                      outlined
                      hide-details
                    ></v-select>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6 col-12">
                  <div class="form-group">
                    <label class="form-label">{{
                      $t("player.athlete_info.dominant_foot")
                    }}</label>
                    <v-radio-group
                      v-model="informationForm.account_information_feet_skill"
                      :readonly="!isEditable"
                      row
                      dense
                    >
                      <v-radio
                        on-icon="fa-circle"
                        value="เท้าขวา"
                        :label="$t('player.athlete_info.left_foot')"
                      ></v-radio>
                      <v-radio
                        on-icon="fa-circle"
                        value="เท้าซ้าย"
                        :label="$t('player.athlete_info.right_foot')"
                      ></v-radio>
                      <v-radio
                        on-icon="fa-circle"
                        value="ถนัดทั้งสองเท้า"
                        :label="$t('player.athlete_info.two_foot')"
                      ></v-radio>
                    </v-radio-group>
                  </div>
                </div>
                <div class="col-md-6 col-12">
                  <div class="form-group">
                    <label class="form-label" for="basic-addon-name">{{
                      $t("player.athlete_info.dominant_hand")
                    }}</label>
                    <v-radio-group
                      v-model="informationForm.account_information_hand_skill"
                      :readonly="!isEditable"
                      row
                      dense
                    >
                      <v-radio
                        on-icon="fa-circle"
                        value="มือขวา"
                        :label="$t('player.athlete_info.left_hand')"
                      ></v-radio>
                      <v-radio
                        on-icon="fa-circle"
                        value="มือซ้าย"
                        :label="$t('player.athlete_info.right_hand')"
                      ></v-radio>
                      <v-radio
                        on-icon="fa-circle"
                        value="ถนัดทั้งสองมือ"
                        :label="$t('player.athlete_info.two_hand')"
                      ></v-radio>
                    </v-radio-group>
                  </div>
                </div>
              </div>
            </template>
            <template v-else>
              <h4 class="card-title text-primary">
                {{ $t("staff.staff_specification") }}
              </h4>
              <div class="row">
                <div class="col-12 col-md-6">
                  <div class="form-group">
                    <label class="form-label">{{
                      $t("staff.position_mission")
                    }}</label>
                    <v-select
                      hide-details
                      :placeholder="$t('normal.please_select')"
                      dense
                      v-model="specificationForm.global_config_positions[0]"
                      :readonly="!isEditable"
                      :items="positions"
                      :item-text="'global_config_position_name_' + language"
                      item-value="id"
                      outlined
                    ></v-select>
                  </div>
                </div>
                <div class="col-12 col-md-6">
                  <div class="form-group">
                    <label class="form-label">{{
                      $t("staff.department")
                    }}</label>
                    <v-select
                      hide-details
                      :placeholder="$t('normal.please_select')"
                      dense
                      v-model="specificationForm.global_config_departments"
                      :readonly="!isEditable"
                      :items="departments"
                      :item-text="'global_config_department_name_' + language"
                      item-value="id"
                      outlined
                    ></v-select>
                  </div>
                </div>
              </div>
            </template>
            <div class="row">
              <div class="col-6 col-md-3">
                <div class="form-group">
                  <label class="form-label" for="basic-addon-name"
                    >{{ $t("player.athlete_info.weight") }} <b>kg.</b></label
                  >
                  <v-text-field
                    type="number"
                    dense
                    outlined
                    v-model="informationForm.account_information_weight"
                    :readonly="!isEditable"
                    :placeholder="$t('player.athlete_info.weight')"
                  ></v-text-field>
                </div>
              </div>
              <div class="col-6 col-md-3">
                <div class="form-group">
                  <label class="form-label" for="basic-addon-name"
                    >{{ $t("player.athlete_info.height") }} <b>cm.</b></label
                  >
                  <v-text-field
                    type="number"
                    :placeholder="$t('player.athlete_info.height')"
                    dense
                    outlined
                    v-model="informationForm.account_information_height"
                    :readonly="!isEditable"
                  ></v-text-field>
                </div>
              </div>
              <div class="col-6 col-md-3">
                <div class="form-group">
                  <label class="form-label" for="basic-addon-name">{{
                    $t("player.athlete_info.blood")
                  }}</label>
                  <v-select
                    :items="bloodTypes"
                    :placeholder="$t('normal.please_select')"
                    dense
                    outlined
                    v-model="informationForm.account_information_blood_type"
                    :readonly="!isEditable"
                  ></v-select>
                </div>
              </div>
            </div>
          </section>
          <section v-if="isPlayerRoute" class="py-5 border-bottom">
            <div class="row">
              <div class="col-md-6 col-12 order-1">
                <h4
                  class="card-title text-primary d-flex justify-content-between"
                >
                  <span>{{ $t("player.athlete_info.ready_athlete") }}</span>
                  <!-- <div>
                    <v-icon
                      small
                      @click="isFitnessEditable = !isFitnessEditable"
                      color="#92929D"
                      class="cursor-pointer"
                      >{{ isFitnessEditable ? "fa-check" : "fa-edit" }}</v-icon
                    >
                  </div> -->
                </h4>
              </div>
              <div class="col-md-6 col-12 order-md-2 order-3">
                <h4
                  class="card-title text-primary d-flex justify-content-between"
                >
                  <span>
                    {{ $t("player.athlete_info.skill") }}
                  </span>
                  <!-- <div>
                    <v-icon
                      small
                      @click="isSkillEditable = !isSkillEditable"
                      color="#92929D"
                      class="cursor-pointer"
                      >{{ isSkillEditable ? "fa-check" : "fa-edit" }}</v-icon
                    >
                  </div> -->
                </h4>
              </div>
              <div class="col-md-6 col-12 order-md-3 order-2">
                <div
                  class="
                    border
                    rounded
                    h-100
                    d-flex
                    align-items-center
                    justify-content-center
                    p-2
                  "
                >
                  <div class="text-center">
                    <v-progress-circular
                      :rotate="-90"
                      :size="100"
                      :width="3"
                      :value="specificationForm.account_physical_fitness"
                      :color="fitColor"
                    >
                      {{ specificationForm.account_physical_fitness }} %
                    </v-progress-circular>

                    <v-rating
                      :value="specificationForm.account_physical_fitness / 10"
                      @input="onChangeFitness"
                      length="10"
                      class="mt-3"
                    >
                      <template v-slot:item="props">
                        <span
                          :style="`background: ${
                            props.isFilled ? fitColor : '#f4f4f7'
                          }`"
                          class="rate"
                          @click="props.click"
                        >
                        </span>
                      </template>
                    </v-rating>

                    <span
                      >{{ fitnessText[language] }} ({{
                        $t("player.athlete_info.completeness_start")
                      }}
                      {{ specificationForm.account_physical_fitness }}%
                      {{ $t("player.athlete_info.completeness_end") }})
                    </span>
                  </div>
                </div>
              </div>
              <div class="col-md-6 col-12 order-4">
                <div class="border rounded p-2 h-100">
                  <div
                    v-for="skill in specificationForm.account_technical_skills"
                    :key="skill.id"
                  >
                    <v-subheader class="px-0 row no-gutters">
                      <div class="col">
                        <span>{{ skill.name[language] }}</span>
                      </div>
                      <div class="col-2 text-right">
                        <v-text-field
                          v-model="skill.account_technical_skill_percentage"
                          :readonly="!isEditable"
                          class="text-right pt-0 mt-0"
                          hide-details
                          suffix="%"
                          v-range="{ max: 100, min: 0 }"
                          dense
                          outlined
                        ></v-text-field>
                      </div>
                    </v-subheader>

                    <v-progress-linear
                      color="#3DD598"
                      background-color="#E2E2EA"
                      rounded
                      :value="
                        Math.ceil(skill.account_technical_skill_percentage)
                      "
                      height="15"
                    >
                      <strong
                        >{{
                          Math.ceil(skill.account_technical_skill_percentage)
                        }}%</strong
                      >
                    </v-progress-linear>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section class="py-5">
            <h4 class="card-title text-primary">
              {{ $t("player.athlete_info.medical") }}
            </h4>
            <div
              class="row border-bottom"
              v-for="(item, idx) in congentialList"
              :key="`CONGENTIAL_DISEASE-${idx}`"
            >
              <div class="col-md-5 col-12">
                <div class="form-group">
                  <label class="form-label" for="basic-addon-name"
                    >{{ $t("player.athlete_info.congential_th") }}
                  </label>
                  <v-text-field
                    dense
                    outlined
                    hide-details
                    @input="changeValueCongential"
                    v-model="item.account_medical_condition_name_th"
                    :readonly="!isEditable"
                    :placeholder="$t('player.athlete_info.congential_th')"
                  >
                  </v-text-field>
                </div>
              </div>
              <div class="col-md-5 col-12">
                <div class="form-group">
                  <label class="form-label" for="basic-addon-name">{{
                    $t("player.athlete_info.congential_en")
                  }}</label>
                  <v-text-field
                    dense
                    @input="changeValueCongential"
                    outlined
                    hide-details
                    v-model="item.account_medical_condition_name_en"
                    :readonly="!isEditable"
                    :placeholder="$t('player.athlete_info.congential_en')"
                  >
                  </v-text-field>
                </div>
              </div>
              <div
                v-if="isEditable"
                class="col-md-1 col-sm-2 col-3 d-flex align-items-end"
              >
                <div class="form-group mr-2">
                  <v-btn
                    dark
                    color="red"
                    @click="() => addDeleteMedicalList(item)"
                  >
                    <v-icon dark> fa-trash </v-icon>
                  </v-btn>
                </div>
              </div>
              <div
                v-if="idx === congentialList.length - 1 && isEditable"
                class="col-md-1 col-sm-2 col-3 d-flex align-items-end"
              >
                <div class="form-group">
                  <v-btn
                    :disabled="disableMedical[0]"
                    color="primary"
                    @click="() => addMedical('CONGENTIAL_DISEASE')"
                  >
                    <v-icon dark> fa-plus </v-icon>
                  </v-btn>
                </div>
              </div>
            </div>
            <div
              class="row border-bottom"
              v-for="(item, idx) in drugAllergyList"
              :key="`DRUG_ALLERGY-${idx}`"
            >
              <div class="col-md-5 col-12">
                <div class="form-group">
                  <label class="form-label" for="basic-addon-name"
                    >{{ $t("player.athlete_info.drug_allerfy_th") }}
                  </label>
                  <v-text-field
                    dense
                    outlined
                    hide-details
                    @input="changeValueDrug"
                    v-model="item.account_medical_condition_name_th"
                    :readonly="!isEditable"
                    :placeholder="$t('player.athlete_info.drug_allerfy_th')"
                  >
                  </v-text-field>
                </div>
              </div>
              <div class="col-md-5 col-12">
                <div class="form-group">
                  <label class="form-label" for="basic-addon-name">{{
                    $t("player.athlete_info.drug_allerfy_en")
                  }}</label>
                  <v-text-field
                    dense
                    outlined
                    @input="changeValueDrug"
                    hide-details
                    v-model="item.account_medical_condition_name_en"
                    :readonly="!isEditable"
                    :placeholder="$t('player.athlete_info.drug_allerfy_en')"
                  >
                  </v-text-field>
                </div>
              </div>
              <div
                v-if="isEditable"
                class="col-md-1 col-sm-2 col-3 d-flex align-items-end"
              >
                <div class="form-group mr-2">
                  <v-btn
                    dark
                    color="red"
                    @click="() => addDeleteMedicalList(item)"
                  >
                    <v-icon dark> fa-trash </v-icon>
                  </v-btn>
                </div>
              </div>
              <div
                v-if="idx === drugAllergyList.length - 1 && isEditable"
                class="col-md-1 col-sm-2 col-3 d-flex align-items-end"
              >
                <div class="form-group">
                  <v-btn
                    color="primary"
                    :disabled="disableMedical[1]"
                    @click="() => addMedical('DRUG_ALLERGY')"
                  >
                    <v-icon dark> fa-plus </v-icon>
                  </v-btn>
                </div>
              </div>
            </div>
            <div
              class="row border-bottom"
              v-for="(item, idx) in foodAlergyList"
              :key="`FOOd_ALLERGY-${idx}`"
            >
              <div class="col-md-5 col-12">
                <div class="form-group">
                  <label class="form-label" for="basic-addon-name"
                    >{{ $t("player.athlete_info.food_allerfy_th") }}
                  </label>
                  <v-text-field
                    dense
                    outlined
                    @input="changeValueFood"
                    hide-details
                    v-model="item.account_medical_condition_name_th"
                    :placeholder="$t('player.athlete_info.food_allerfy_th')"
                  >
                  </v-text-field>
                </div>
              </div>
              <div class="col-md-5 col-12">
                <div class="form-group">
                  <label class="form-label" for="basic-addon-name">{{
                    $t("player.athlete_info.food_allerfy_en")
                  }}</label>
                  <v-text-field
                    dense
                    outlined
                    hide-details
                    @input="changeValueFood"
                    v-model="item.account_medical_condition_name_en"
                    :placeholder="$t('player.athlete_info.food_allerfy_en')"
                  >
                  </v-text-field>
                </div>
              </div>
              <div
                v-if="isEditable"
                class="col-md-1 col-sm-2 col-3 d-flex align-items-end"
              >
                <div class="form-group mr-2">
                  <v-btn
                    dark
                    color="red"
                    @click="() => addDeleteMedicalList(item)"
                  >
                    <v-icon dark> fa-trash </v-icon>
                  </v-btn>
                </div>
              </div>
              <div
                v-if="idx === foodAlergyList.length - 1 && isEditable"
                class="col-md-1 col-sm-2 col-3 d-flex align-items-end"
              >
                <div class="form-group">
                  <v-btn
                    :disabled="disableMedical[2]"
                    color="primary"
                    @click="() => addMedical('FOOD_ALLERGY')"
                  >
                    <v-icon dark> fa-plus </v-icon>
                  </v-btn>
                </div>
              </div>
            </div>
          </section>
          <section class="py-5">
            <h4 class="card-title text-primary">
              {{ label.history[language] }}
            </h4>
            <div class="row">
              <div class="col-12">
                <div class="form-group">
                  <label class="form-label" for="basic-addon-name"
                    >{{ $t("player.athlete_info.history_th") }}
                  </label>
                  <vue-editor
                    v-model="informationForm.account_information_biography_th"
                    :editorToolbar="customToolbar"
                    :disabled="!isEditable"
                  ></vue-editor>
                </div>
              </div>

              <div class="col-12">
                <div class="form-group">
                  <label class="form-label" for="basic-addon-name"
                    >{{ $t("player.athlete_info.history_en") }}
                  </label>
                  <vue-editor
                    v-model="informationForm.account_information_biography_en"
                    :editorToolbar="customToolbar"
                    :disabled="!isEditable"
                  ></vue-editor>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
      <div class="d-flex justify-content-between">
        <v-btn
          elevation="2"
          class="btn-primary float-left mb-5"
          v-on:click="backTab()"
        >
          <v-icon left> fa-arrow-left </v-icon> {{ $t("normal.previous") }}
        </v-btn>
        <v-btn
          elevation="2"
          class="btn-primary float-right mb-5"
          v-on:click="nextTab()"
        >
          {{ $t("normal.next") }} <v-icon right> fa-arrow-right </v-icon>
        </v-btn>
      </div>
    </div>
  </div>
</template>
<script>
import { VueEditor } from "vue2-editor";
import { appAxios } from "../../helpers/app-axios";
import range from "../../directives/rangeValue";
import { languageService } from "../../helpers/language";
import MasterDataHelper from "@/helpers/master_data_helper.js";

export default {
  directives: {
    range,
  },
  components: {
    VueEditor,
  },
  name: "player-tabs3",
  async mounted() {
    const accountId = this.$route.params.id;
    this.$data.accountId = accountId;
    this.getBloodType();
    this.getSportCategory();
    if (this.$route.path.includes("player")) {
      this.$data.isPlayerRoute = true;
    }
    if (this.$data.isPlayerRoute) {
      await this.getSkills();
      this.getFitness();
    } else {
      this.getDepartment();
    }
    this.getPosition();
    this.getInformation();
    this.getSpecification();
    this.getImagePreview();
  },
  methods: {
    changeValueDrug() {
      const disableButton = this.drugAllergyList.find(
        (n) =>
          (n.account_medical_condition_name_en === null ||
            n.account_medical_condition_name_en === "") &&
          (n.account_medical_condition_name_th === null ||
            n.account_medical_condition_name_th === "")
      );
      if (disableButton) {
        this.disableMedical[1] = true;
      } else {
        this.disableMedical[1] = false;
      }
    },
    changeValueCongential() {
      const disableButton = this.congentialList.find(
        (n) =>
          (n.account_medical_condition_name_en === null ||
            n.account_medical_condition_name_en === "") &&
          (n.account_medical_condition_name_th === null ||
            n.account_medical_condition_name_th === "")
      );
      if (disableButton) {
        this.disableMedical[0] = true;
      } else {
        this.disableMedical[0] = false;
      }
    },
    changeValueFood() {
      const disableButton = this.foodAlergyList.find(
        (n) =>
          (n.account_medical_condition_name_en === null ||
            n.account_medical_condition_name_en === "") &&
          (n.account_medical_condition_name_th === null ||
            n.account_medical_condition_name_th === "")
      );
      if (disableButton) {
        this.disableMedical[2] = true;
      } else {
        this.disableMedical[2] = false;
      }
    },
    addMedical(type) {
      this.$data.informationForm.medical_conditions.push({
        account_medical_condition_name_th: null,
        account_medical_condition_name_en: null,
        account_medical_condition_type: type,
        idx: this.$data.informationForm.medical_conditions.length,
      });
    },
    handleChangeSport(value) {
      if (value === null) {
        this.specificationForm.global_config_sport_categories =
          this.specificationForm?.global_config_sport_categories.filter(
            (p) => p !== null
          );
      }
    },
    handleChangePosition(value) {
      if (value === null) {
        this.specificationForm.global_config_positions =
          this.specificationForm.global_config_positions.filter(
            (p) => p !== null
          );
      }
    },
    removeMedical(idx) {
      this.$data.informationForm.medical_conditions.splice(idx, 1);
      // this.$data.informationForm.medical_conditions = newMedical;
    },
    onChangeFitness(value) {
      if (!this.isEditable) return;
      this.$data.specificationForm.account_physical_fitness = value * 10;
    },
    async getBloodType() {
      return appAxios.get("account/blood-type").then((res) => {
        const data = res.data?.data;
        if (!data) return;
        this.$data.bloodTypes = Object.keys(data).map((ele) => ({
          text: ele,
          value: ele,
        }));
      });
    },
    async getImagePreview() {
      const type = this.$data.isPlayerRoute ? "players" : "staffs";
      return appAxios
        .get(`account/${this.$data.accountId}/medias/${type}/preview`)
        .then((res) => {
          const data = res.data?.data;
          if (!data) return;
          if (data?.preview?.account_media_preview_user_panel !== undefined) {
            this.$data.dataImage =
              res.data.data.preview.account_media_preview_user_panel;
          }
        });
    },
    async getSportCategory() {
      return appAxios.get("setting/global/sport-categories/all").then((res) => {
        const data = res.data?.data;
        if (!data) return;
        this.$data.sportCategories = data;
      });
    },
    async getPosition() {
      const personType = this.$data.isPlayerRoute ? "player" : "staff";
      return appAxios
        .get(`setting/global/positions/${personType}/all`)
        .then((res) => {
          const data = res.data?.data;
          if (!data) return;
          this.$data.positions = data;
        });
    },
    async getDepartment() {
      return appAxios.get("setting/global/departments/all").then((res) => {
        this.$data.departments = res.data?.data || [];
      });
    },
    async getFitness() {
      return appAxios.get(`setting/global/physical-fitness`).then((res) => {
        const data = res.data?.data;
        if (!data) return;
        this.$data.fitness = data;
      });
    },
    async getSkills() {
      return appAxios.get("setting/global/skill").then((res) => {
        const data = res.data?.data;
        if (!data) return;
        const parseSkills = data
          .filter((ele) => ele.active)
          .map((ele) => {
            let matchSkill;
            if (
              this.$data.specificationForm.account_technical_skills.length > 0
            ) {
              matchSkill =
                this.$data.specificationForm.account_technical_skills.find(
                  (ele) => ele.global_technical_skill_id == ele.id
                );
            }
            if (matchSkill) {
              return {
                global_technical_skill_id: matchSkill.global_technical_skill_id,
                account_technical_skill_percentage:
                  matchSkill.account_technical_skill_percentage,
                name: ele.global_config_skill_name_th,
              };
            }
            return {
              global_technical_skill_id: ele.id,
              account_technical_skill_percentage: "0",
              name: {
                en: ele.global_config_skill_name_en,
                th: ele.global_config_skill_name_th,
              },
            };
          });
        this.$data.specificationForm.account_technical_skills = parseSkills;
      });
    },
    async getSpecification() {
      return appAxios
        .get(`account/${this.$data.accountId}/specifications`)
        .then((res) => {
          const data = res.data?.data;
          if (!data) return;
          this.parseSpecification(data);
        });
    },
    parseSpecification(data) {
      let parseSkills = this.$data.specificationForm.account_technical_skills;
      if (this.$data.specificationForm.account_technical_skills.length > 0) {
        parseSkills = this.$data.specificationForm.account_technical_skills.map(
          (exist) => {
            const matchSkill = data.account_technical_skills.find((ele) => {
              return (
                ele.global_technical_skill_id.id ==
                exist.global_technical_skill_id
              );
            });
            if (matchSkill) {
              return {
                ...exist,
                account_technical_skill_percentage: Number(
                  matchSkill.account_technical_skill_percentage
                ).toString(),
                name: exist.name,
              };
            }
            return exist;
          }
        );
      }
      this.$data.specificationForm = {
        ...this.$data.specificationForm,
        ...data,
        account_specification_main_position:
          data.account_specification_main_position || this.isPlayerRoute
            ? data.account_specification_main_position
            : null,
        account_physical_fitness:
          data.account_physical_fitness
            ?.global_config_physical_fitness_percentage || 10,
        account_technical_skills: parseSkills,
      };
    },
    async getInformation() {
      return appAxios
        .get(`account/${this.$data.accountId}/informations`)
        .then((res) => {
          const data = res.data?.data;
          if (!data) return;
          this.parseInformation(data);
        });
    },
    parseInformation(data) {
      const medicalCondition =
        data.medical_conditions && data.medical_conditions.length > 0
          ? data.medical_conditions
          : [
              {
                account_medical_condition_name_th: null,
                account_medical_condition_name_en: null,
                account_medical_condition_type: "CONGENTIAL_DISEASE",
              },
              {
                account_medical_condition_name_th: null,
                account_medical_condition_name_en: null,
                account_medical_condition_type: "DRUG_ALLERGY",
              },
              {
                account_medical_condition_name_th: null,
                account_medical_condition_name_en: null,
                account_medical_condition_type: "FOOD_ALLERGY",
              },
            ];
      this.$data.informationForm = {
        ...data,
        medical_conditions: medicalCondition,
      };
    },
    backTab() {
      this.$router.push({ path: "", query: { tab: 0 } });
    },
    async nextTab() {
      const result = await this.save();
      if (!result) return;
      return this.$router
        .push({
          path: "",
          query: { tab: 2 },
        })
        .catch(() => {});
    },
    async save() {
      // console.log(this.informationForm);
      if (!this.isEditable) return true;
      await this.deleteMedicalConditionList();
      await this.saveSpecification();
      if (this.$data.item2.image !== null) {
        await this.savePreview();
      }
      await this.saveInformation();
      return true;
    },
    async saveSpecification() {
      const body = this.specificationForm;

      if (this.specificationForm?.account_honors !== null) {
        body["account_honors"] = this.specificationForm?.account_honors?.id;
      }

      // delete body
      return appAxios
        .post(`account/${this.$data.accountId}/specifications`, body)
        .then((res) => {
          const data = res.data?.data;
          if (!data) return;
          this.parseSpecification(data);
        });
    },
    async savePreview() {
      //true === player , false ==== staff
      const type = this.$data.isPlayerRoute ? "players" : "staffs";
      let formData = new FormData();
      formData.append("file", this.$data.item2.image);
      appAxios
        .post(
          `account/${this.$data.accountId}/medias/${type}/preview`,
          formData
        )
        .then((res) => res);
    },
    async saveInformation() {
      const body = this.$data.informationForm;
      return appAxios
        .post(`account/${this.$data.accountId}/informations`, body)
        .then((res) => {
          const data = res.data?.data;
          if (!data) return;
          this.parseInformation(data);
        });
    },
    addDeleteMedicalList(medicalConditionItem) {
      if (!this.isEditable) return;
      if (!medicalConditionItem.id) {
        this.removeMedical(medicalConditionItem.idx);
        return;
      }
      const medicalConditionIdx =
        this.$data.informationForm.medical_conditions.findIndex(
          (ele) => ele.id === medicalConditionItem.id
        );
      if (!medicalConditionIdx === -1) return;
      this.$swal(MasterDataHelper.sweetAlertConfig(this.language)).then(
        (result) => {
          if (result.value) {
            this.removeMedical(medicalConditionIdx);
          }
        }
      );
      this.$data.shouldDeleteMedical.push(medicalConditionItem.id);
    },
    async deleteMedicalConditionList() {
      return await Promise.all(
        this.$data.shouldDeleteMedical.map(async (ele) => {
          return await appAxios.post(
            `account/${this.$data.accountId}/medical-conditions/${ele}/delete`
          );
        })
      );
    },
    onChangeImage2(e) {
      const file = e.target.files[0];

      this.$data.item2.preview = e;
      this.$data.item2.image = file;
      this.$data.item2.imageUrl2 = URL.createObjectURL(file);
    },
  },
  created() {
    this.subscription = languageService.getCurrent().subscribe((lang) => {
      if (lang === "en") {
        this.language = "en";
      } else {
        this.language = "th";
      }
    });
  },
  computed: {
    languageSport(){
      return this.$t('short_language')
    },
    fitnessText() {
      const value = this.specificationForm.account_physical_fitness;
      const result_th =
        this.fitness[value / 10 - 1]?.global_config_physical_fitness_name_th;
      const result_en =
        this.fitness[value / 10 - 1]?.global_config_physical_fitness_name_en;
      return {
        th: result_th,
        en: result_en,
      };
    },
    fitColor() {
      const fitness = this.$data.specificationForm.account_physical_fitness;
      if (fitness <= 30) {
        return "red";
      } else if (fitness <= 60) {
        return "#FF974A";
      } else if (fitness <= 80) {
        return "#E6CF00";
      } else {
        return "#3DD598";
      }
    },
    congentialList() {
      const congentialItems =
        this.$data.informationForm.medical_conditions?.filter(
          (ele) => ele.account_medical_condition_type === "CONGENTIAL_DISEASE"
        );
      return congentialItems || [];
    },
    drugAllergyList() {
      const drugAllergyItems =
        this.$data.informationForm.medical_conditions?.filter(
          (ele) => ele.account_medical_condition_type === "DRUG_ALLERGY"
        );
      return drugAllergyItems || [];
    },
    foodAlergyList() {
      const foodAlergyItems =
        this.$data.informationForm.medical_conditions?.filter(
          (ele) => ele.account_medical_condition_type === "FOOD_ALLERGY"
        );
      return foodAlergyItems || [];
    },
    label() {
      return this.$data.isPlayerRoute
        ? {
            specificationTitle: {
              th: "ข้อมูลเฉพาะนักกีฬา",
              en: "Specific Information",
            },
            history: {
              th: "ประวัตินักกีฬา",
              en: "Biography",
            },
          }
        : {
            specificationTitle: {
              th: "ข้อมูลเฉพาะของบุคลากร",
              en: "Specific Information",
            },
            history: {
              th: "ประวัติบุคลากร",
              en: "Biography",
            },
          };
    },
    isEditable() {
      if (this.$data.isPlayerRoute) {
        return this.$store.state.permission?.permissions?.athlete_edit;
      }
      return this.$store.state.permission?.permissions?.staff_edit;
    },
  },
  watch: {
    "informationForm.medical_conditions"(val) {
      const congential = val.find(
        (p) => p.account_medical_condition_type === "CONGENTIAL_DISEASE"
      );
      if (
        congential.account_medical_condition_name_en !== null ||
        congential.account_medical_condition_name_th !== null
      ) {
        this.disableMedical[0] = false;
      }

      const drug = val.find(
        (p) => p.account_medical_condition_type === "DRUG_ALLERGY"
      );
      if (
        drug.account_medical_condition_name_en !== null ||
        drug.account_medical_condition_name_th !== null
      ) {
        this.disableMedical[1] = false;
      }

      const food = val.find(
        (p) => p.account_medical_condition_type === "FOOD_ALLERGY"
      );
      if (
        food.account_medical_condition_name_en !== null ||
        congential.account_medical_condition_name_th !== null
      ) {
        this.disableMedical[2] = false;
      }
    },
    "specificationForm.global_config_sport_categories"() {
      if (this.specificationForm.global_config_sport_categories.length === 0) {
        this.typeSport[0].disable = false;
        this.typeSport[1].disable = true;
        this.typeSport[2].disable = true;

      } else if (
        this.specificationForm.global_config_sport_categories[0] !== null
      ) {
        this.typeSport[1].disable = false;
      }

      if (this.specificationForm.global_config_sport_categories.length === 1) {
        this.typeSport[2].disable = true;
      }

      if (this.specificationForm.global_config_sport_categories.length >= 2) {
        this.typeSport[2].disable = false;
      }
    },
    "specificationForm.global_config_positions"() {
      if (this.specificationForm.global_config_positions.length === 0) {
        this.positionSport[0].disable = false;
        this.positionSport[1].disable = true;
        this.positionSport[2].disable = true;
      } else if (this.specificationForm.global_config_positions[0] !== null) {
        this.positionSport[1].disable = false;
      }
      console.log('global_config_positions',this.specificationForm.global_config_positions);
      if (this.specificationForm.global_config_positions.length >= 2) {
        this.positionSport[2].disable = false;
      }
    },

    congentialList(newVal) {
      if (newVal.length === 0) {
        this.addMedical("CONGENTIAL_DISEASE");
      }
      const disableButton = newVal.find(
        (n) =>
          n.account_medical_condition_name_en === null &&
          n.account_medical_condition_name_th === null
      );
      if (disableButton) {
        this.disableMedical[0] = true;
      }
    },
    drugAllergyList(newVal) {
      const disableButton = newVal.find(
        (n) =>
          n.account_medical_condition_name_en === null &&
          n.account_medical_condition_name_th === null
      );
      if (disableButton) {
        this.disableMedical[1] = true;
      }
      if (newVal.length === 0) {
        this.addMedical("DRUG_ALLERGY");
      }
    },
    foodAlergyList(newVal) {
      if (newVal.length === 0) {
        this.addMedical("FOOD_ALLERGY");
      }
      const disableButton = newVal.find(
        (n) =>
          n.account_medical_condition_name_en === null &&
          n.account_medical_condition_name_th === null
      );
      if (disableButton) {
        this.disableMedical[2] = true;
      }
    },
  },
  data() {
    return {
      language: "th",
      item2: {
        image: null,
        imageUrl2: null,
        preview: null,
      },
      disableMedical: [true, true, true],
      typeSport: [
        {
          en: "Main Sport Category",
          th: "ประเภทกีฬาหลัก",
          disable: false,
        },
        {
          en: "Sport Category #2",
          th: "ประเภทกีฬาที่ 2",
          disable: true,
        },
        {
          en: "Sport Category #3",
          th: "ประเภทกีฬาที่ 3",
          disable: true,
        },
      ],
      positionSport: [
        {
          en: "Main Position",
          th: "ตำแหน่งการเล่นหลัก",
          disable: false,
        },
        {
          en: "Position #2",
          th: "ตำแหน่งการเล่น 2",
          disable: true,
        },
        {
          en: "Position #3",
          th: "ตำแหน่งการเล่น 3",
          disable: true,
        },
      ],
      customToolbar: [
        [{ size: [] }],
        ["bold", "italic", "underline", "strike"],
        [
          { align: "" },
          { align: "center" },
          { align: "right" },
          { align: "justify" },
        ],
        ["blockquote", "code-block"],
        [{ list: "ordered" }, { list: "bullet" }, { list: "check" }],
        [{ indent: "-1" }, { indent: "+1" }],
        [{ color: [] }, { background: [] }],
        ["link"],
      ],
      accountId: null,
      isPlayerRoute: false,
      positions: [],
      fitness: [],
      contentThai: "<h1>Some initial content</h1>",
      contentEng: [],
      // isFitnessEditable: false,
      // isSkillEditable: false,
      bloodTypes: [],
      sportCategories: [],
      departments: [],
      mainPositions: [
        {
          value: "GOALKEEPER",
          label: {
            th: "ผู้รักษาประตู",
            en: "Goalkeeper",
          },
        },
        {
          value: "DEFENDER",
          label: {
            th: "กองหลัง",
            en: "Defender",
          },
        },
        {
          value: "MIDFIELDER",
          label: {
            th: "กองกลาง",
            en: "Midfielder",
          },
        },
        {
          value: "FORWARD",
          label: {
            th: "กองหน้า",
            en: "Forward",
          },
        },
      ],
      dataImage: null,
      informationForm: {
        account_information_height: 0,
        account_information_weight: 0,
        account_information_biography_th: null,
        account_information_biography_en: null,
        account_information_hand_skill: null,
        account_information_feet_skill: null,
        account_information_blood_type: null,
        medical_conditions: [],
      },
      specificationForm: {
        global_config_positions: [],
        global_config_departments: null,
        global_config_sport_categories: [],
        account_physical_fitness: 10,
        account_technical_skills: [],
        account_specification_main_position: "GOALKEEPER",
      },
      shouldDeleteMedical: [],
    };
  },
};
</script>

<style lang="scss" scoped>
.rate {
  width: 15px;
  height: 30px;
  display: inline-block;
  margin: 0 2px;
  cursor: pointer;
}
.show-image {
  background: url("~@/assets/images/person-bg.png");
  background-repeat: no-repeat;
  background-size: cover;
}

.show-image-staff {
  background: url("~@/assets/images/staff-bg.png");
  background-size: cover;
  background-repeat: no-repeat;
}
.image-upload {
  position: absolute;
  top: 0;
  left: 80%;
}
.image-upload .uploadImage {
  position: absolute;
  cursor: pointer;
}
.image-upload .selectFile {
  display: none;
}
</style>
